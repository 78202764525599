<template>
  <div :key="'options' + key">
    <div v-if="filtre.champ === 'input'" class="filtre">
      <input :disabled="disabled" :type="filtre.type" v-model="filtre.selection" :placeholder="filtre.label" :class="filtre.class || ''" :style="'width:' + (filtre.width || '250px')" @input="appliquerFiltre(filtre)" @blur="appliquerFiltre(filtre)" @focus="$event.target.select()">
    </div>
    <div v-if="filtre.champ === 'select'" class="filtre">
      <MultiSelect v-if="filtre.type === 'multi'" :disabled="disabled" :value="filtre.selection" :items="options" itemText="texte" itemValue="valeur" :placeholder="filtre.label" emptyText="Toutes les valeurs disponibles ont été ajoutées" @input="appliquerFiltre(filtre, $event)" :style="'min-width:' + (filtre.width || '300px')" />
      <v-select v-else :disabled="disabled" v-model="filtre.selection" :options="options" label="texte" value="valeur" :clearable="false" :placeholder="filtre.label" :style="'min-width:' + (filtre.width || '300px')" @input="appliquerFiltre(filtre)"></v-select>
    </div>
    <div v-if="filtre.champ === 'datepicker'" class="filtre">
      <v-date-picker v-if="filtre.type === 'multi'" class="date-picker" :disabled="disabled" :disabled-dates="filtre.disabledDates" :available-dates="filtre.availableDates" is-range :columns="2" :step="1" v-model="filtre.selection" :is-required="filtre.required" :popover="{ visibility: 'click' }" @input="appliquerFiltre(filtre)">
        <template v-slot="{ inputValue, inputEvents }">
          <input :value="filtre.customText ? filtre.customText(filtre) : inputValue.start || inputValue.end ? inputValue.start + ' au ' + inputValue.end : filtre.label" v-on="inputEvents.start" :readonly="filtre.verrouClavier" />
        </template>
      </v-date-picker>
      <v-date-picker v-else class="date-picker" :disabled="disabled" :disabled-dates="filtre.disabledDates" :available-dates="filtre.availableDates" :columns="1" :step="1" v-model="filtre.selection" :is-required="filtre.required" :popover="{ visibility: 'click' }" @input="appliquerFiltre(filtre)">
        <template v-slot="{ inputValue, inputEvents }">
          <input :value="filtre.customTextSemaine ? customTextSemaine(filtre.selection) : inputValue ? inputValue : filtre.label" v-on="inputEvents" :readonly="filtre.verrouClavier" />
        </template>
      </v-date-picker>
    </div>
    <div v-if="filtre.champ === 'checkbox'" class="filtre">
      <label class="label-checkbox mb-2">
        <input type="checkbox" v-model="filtre.selection" @change="appliquerFiltre(filtre)">
        <span class="ml-2">{{ filtre.label }}</span>
      </label>
    </div>
    <div v-if="filtre.champ === 'rechercheProduits'" class="filtre">
      <RechercheCompleteProduits :placeHolder="filtre.label" :selectionActive="false" :chercherProduits="filtre.chercherProduits" :chercherIngredients="filtre.chercherIngredients" :chercherRecettes="filtre.chercherRecettes" @input="appliquerFiltre(filtre, $event)" />
    </div>
    <div v-if="filtre.champ === 'btn'" class="filtre">
      <b-button class="btn" :class="filtre.class ? filtre.class : 'btn-primary'" :hidden="filtre.hidden" :disabled="disabled || filtre.disabled" @click="executerAction(filtre)"><i v-if="filtre.picto" :class="filtre.picto"></i>{{ filtre.label }}</b-button>
    </div>
  </div>
</template>

<script>
import MultiSelect from "@/components/Inputs/MultiSelect"
import RechercheCompleteProduits from "@/components/Mercuriales/RechercheCompleteProduits"
import { getLundiDeLaSemaine, getDimancheDeLaSemaine } from '@/mixins/index'

export default {
  name: "OptionsBarreFiltres",
  props: [ "filtre", "disabled" ],
  mixins: [ getLundiDeLaSemaine, getDimancheDeLaSemaine ],
  components: { MultiSelect, RechercheCompleteProduits },
  data() {
    return {
      key: 1
    }
  }, 
  mounted() {
  },
  computed: {
    options() {
      return this.filtre.options || []
    }
  },
  watch: {
    options() {
      this.key++
    }
  },
  methods: {
    customTextSemaine(dateSelectionnee) {
      let date = dateSelectionnee || new Date()
      
      let lundi = this.getLundiDeLaSemaine(date)
      let dimanche = this.getDimancheDeLaSemaine(date)

      return lundi.toLocaleDateString('fr-FR') + ' au ' + dimanche.toLocaleDateString('fr-FR')
    },
    executerAction(filtre) {
      this.$emit("executer-action", filtre.filtrer)
    },
    appliquerFiltre(filtre, valeur) {
      if (filtre && valeur) {
        filtre.selection = valeur
      }
      this.$emit("appliquer-filtre", filtre.filtrer)
    },
  }
}
</script>
