<template>
  <div>
    <BoutonAjoutProduit ref="modalAjoutProduit" :fournisseurId="commandeFournisseur.fournisseurId" :title="'Ajouter un article à la commande ' + commandeFournisseur.commandeNom" @ajouter-produit="ajouterArticle(commandeFournisseur, $event)" />
    <BoutonAjoutIncident ref="modalAjoutIncidentGlobal" idModal="Global" :optionsIncident="optionsIncidentGlobal" :optionsTypesDeReclamations="optionsTypesDeReclamations" @incident="ajouterIncident($event, commandeFournisseur);key++" />
    <BoutonAjoutIncident ref="modalAjoutIncidentLigne" size="sm" idModal="IncidentLigne" :optionsIncident="optionsIncidentLigne" :optionsTypesDeReclamations="optionsTypesDeReclamations" @incident="ajouterIncident($event, ligneSelectionnee)" />

    <div class="container-fluid" :key="key">
      <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />

      <div v-if="pageActive" class="card">
        <div class="card-body flex-start">
          <div class="form-group">
            <label for="txtNumBL">Num. BL</label>
            <input type="text" class="container-fluid" id="txtNumBL" v-model.trim="commandeFournisseur.numeroBL" @input="actions.disable(actions, !commandeFournisseur.numeroBL || !commandeFournisseur.dateLivraison)">
            <div class="error" v-if="!$v.commandeFournisseur.numeroBL.required">Ce champ est requis</div>
          </div>

          <div class="form-group ml-2">
            <label for="dpDateLivraison">Date de livraison</label>
            <v-date-picker class="date-picker" id="dpDateLivraison" v-model="commandeFournisseur.dateLivraison" :popover="{ visibility: 'click' }" @input="setDateLivraisonSCommande(commandeFournisseur, $event)">
              <template v-slot="{ inputValue, inputEvents }">
                <input :value="inputValue ? inputValue : 'Date de livraison...'" v-on="inputEvents" />
              </template>
            </v-date-picker>
            <div class="error" v-if="!$v.commandeFournisseur.dateLivraison.required">Ce champ est requis</div>
          </div>
        </div>
      </div>

      <!-- Incident global -->
      <div v-if="commandeFournisseur.raisonIncidentId">
        <div class="alert alert-danger mb-1" role="alert" style="padding:2px">
          <button v-if="pageActive" type="button" class="close" data-dismiss="alert" aria-label="Close" @click="enleverIncident(commandeFournisseur)">
            <span aria-hidden="true" style="vertical-align:middle">&times;</span>
          </button>
          <span style="padding-left:10px">
            {{raisonIncident(commandeFournisseur)}}
          </span>
        </div>
      </div>

      <div class="card table-scroll height-responsive-90">
        <div class="card-header flex-center-space-between">
          <div class="flex-start">
            <span>{{ commandeFournisseur.commandeNom }} - {{ nomFournisseur(commandeFournisseur.fournisseurId) }}</span>
          </div>
          <div class="flex-end">
            <span v-if="!pageActive" class="ml-5">Num. BL : {{ commandeFournisseur.numeroBL }}</span>
            <span v-if="!pageActive" class="ml-5">Date livraison : {{ formatHumanDateAvecSlashes(commandeFournisseur.dateLivraison) }}</span>
            <span v-if="pageActive" class="ml-5">Num. BdC : {{ commandeFournisseur.bonDeCommande }}</span>
            <span v-if="pageActive" class="ml-5">Nb lignes : {{ fournisseurNbLignes(commandeFournisseur.lignes) }}</span>
            <span v-if="pageActive" class="ml-5">Total HT cmd : {{ commandeFournisseur.totalHDT | afficherPrix2 }}</span>
            <span class="ml-5">Total HT livré : {{ fournisseurTotalLivre(commandeFournisseur.lignes) | afficherPrix2 }}</span>
          </div>
        </div>
        <table class="table table-bordered table-sm text-center table-header-secondary">
          <colgroup>
            <col>
            <col>
            <col width="190">
            <col width="190">
            <col width="190">
            <col width="345">
          </colgroup>
          <thead>
            <th class="aligner-gauche">Désignation (Réf.)</th>
            <th class="col-fixed-width-150-sm col-fixed-width-250-lg col-fixed-width-350-xl">Raison incident</th>
            <th>Quantité</th>
            <th>Prix</th>
            <th>Total</th>
            <th>Lots</th>
          </thead>
        </table>
        <table class="table table-bordered table-sm text-center table-header-secondary">
          <colgroup>
            <col>
            <col>
            <col width="95">
            <col width="95">
            <col width="95">
            <col width="95">
            <col width="95">
            <col width="95">
            <col width="50">
            <col width="80">
            <col width="50">
            <col width="100">
            <col width="30">
            <col width="35">
          </colgroup>
          <thead>
            <th></th>
            <th class="col-fixed-width-150-sm col-fixed-width-250-lg col-fixed-width-350-xl"></th>
            <th>Commandée</th>
            <th>Livrée</th>
            <th>Commandé</th>
            <th>Livré</th>
            <th>Commandé</th>
            <th>Livré</th>
            <th>Qté</th>
            <th>N°</th>
            <th>DLC</th>
            <th>DLC/DDM</th>
            <th></th>
            <th></th>
          </thead>
        </table>
        <div class="table-body">
          <table class="table table-bordered table-sm text-center">
            <colgroup>
              <col>
              <col>
              <col width="95">
              <col width="95">
              <col width="95">
              <col width="95">
              <col width="95">
              <col width="95">
              <col width="50">
              <col width="80">
              <col width="50">
              <col width="100">
              <col width="30">
              <col width="30">
            </colgroup>
            <tbody>
              <tr :class="[{'ligne-selectionnee':idLignePourSelection(ligne) === idLigneSelectionnee}]"
                v-for="(ligne, iLigne) in commandeFournisseur.lignes" :key="iLigne" @click="selectionnerLigneCommande(ligne)">
                <td :class="[{'ligne-supprimee':ligne.quantite === 0}]">
                  <div class="flex-space-between">
                    <span>
                      <span v-if="ligne.ancienTarifId">
                        <del>{{ ligne.ancienneDesignation }} {{ligne.ancienneReference ? "(" + ligne.ancienneReference + ")" : "" }})</del>
                        {{designation(commandeFournisseur.fournisseurId, ligne.dAgap, ligne.dFou)}} {{ligne.reference ? "(" + ligne.reference + ")" : ""}}
                      </span>
                      <span v-else-if="ligne.tIdLivre">
                        <del v-if="ligne.tIdLivre !== ligne.tId && (ligne.refLivree !== ligne.reference || ligne.designationLivree !== designation(ligne.foId, ligne.dAgap, ligne.dFou))">
                          {{designation(commandeFournisseur.fournisseurId, ligne.dAgap, ligne.dFou)}} {{ligne.reference ? "(" + ligne.reference + ")" : ""}}
                        </del>
                        {{ligne.designationLivree}} {{ligne.refLivree ? "(" + ligne.refLivree + ")" : ""}}
                      </span>
                      <span v-else>
                        {{designation(commandeFournisseur.fournisseurId, ligne.dAgap, ligne.dFou)}} {{ligne.reference ? "(" + ligne.reference + ")" : ""}}
                      </span>
                    </span>
                    <span v-if="pageActive">
                      <RechercheAjoutProduit v-if="ligne.id" :fournisseurId="commandeFournisseur.fournisseurId" @substituer-article="substituerArticle(ligne, $event)" />
                      <button v-else class="btn btn-primary btn-sm" size="sm" @click="retirerArticle(commandeFournisseur, iLigne)"><i class="fas fa-minus"></i></button>
                    </span>
                  </div>
                </td>
                <td :class="['col-fixed-width-150-sm', 'col-fixed-width-250-lg', 'col-fixed-width-350-xl', {'alert-danger':ligne.raisonIncidentId}]">
                  <div class="flex-end">
                    <div v-if="ligne.raisonIncidentId">
                      {{raisonIncident(ligne)}}
                    </div>
                    <span v-if="pageActive">
                      <button v-if="ligne.raisonIncidentId" class="btn btn-primary btn-sm" size="sm" @click="enleverIncident(ligne)"><i class="fas fa-minus"></i></button>
                      <button v-else class="btn btn-primary btn-sm" size="sm" @click="ouvrirAjoutIncidentLigne(ligne)"><i class="fas fa-exclamation-triangle"></i></button>
                    </span>
                  </div>
                </td>
                <td :class="[{'ligne-supprimee':ligne.quantite === 0}]">
                  <div>{{ ligne.qPCB }} {{ ligne.uc }}</div>
                </td>
                <td :class="[{'ligne-supprimee':ligne.quantite === 0}, {'alert-danger':ligne.quantite !== ligne.qPCB}]">
                  <div v-if="ligne.estLivree">{{ligne.qteLivree}} {{ ligne.uc }}</div>
                  <div v-else class="flex-end">
                    <InputNumber :id="'qteLivree' + idLignePourSelection(ligne)" :key="'quantite'+ligne.foId" v-model="ligne.quantite" step="0.001" typeFiltre="quantite" zeroSiVide="1" @focus="selectionnerLigneCommande(ligne)" @move="deplacerQteLivree($event)" />
                    <span class="input-group-text">{{ ligne.uc }}</span>
                  </div>
                </td>
                <td :class="[{'ligne-supprimee':ligne.quantite === 0}]">
                  <div>{{ligne.pu | afficherPrix}}</div>
                </td>
                <td :class="[{'ligne-supprimee':ligne.quantite === 0}, {'alert-danger': ligne.puLivre.toFixed(3) > ligne.pu.toFixed(3), 'alert-success': ligne.puLivre.toFixed(3) < ligne.pu.toFixed(3)}]">
                  <div v-if="ligne.estLivree">{{ligne.puLivre | afficherPrix}}</div>
                  <div v-else class="flex-end">
                    <InputNumber :id="'puLivre' + idLignePourSelection(ligne)" :key="'pu'+ligne.foId" v-model="ligne.puLivre" typeFiltre="quantite" step="0.001" zeroSiVide="1" @focus="selectionnerLigneCommande(ligne)" @move="deplacerPULivre($event)" />
                    <span class="input-group-text">€</span>
                  </div>
                </td>
                <td :class="[{'ligne-supprimee':ligne.quantite === 0}]">
                  <div>{{ligneTotalPCBCommande(ligne) | afficherPrix2}}</div>
                </td>
                <td :class="[{'ligne-supprimee':ligne.quantite === 0}, {'alert-danger':arrondiNombre(ligne.quantite, 2) < arrondiNombre(ligne.qDem, 2) || arrondiNombre(ligne.puLivre, 3) > arrondiNombre(ligne.pu, 3), 'alert-success':arrondiNombre(ligne.puLivre, 3) < arrondiNombre(ligne.pu, 3)}]">
                  {{ligneTotalLivre(ligne) | afficherPrix2}}
                </td>
                <td v-if="!ligne.lots || !ligne.lots.length"></td>
                <td v-if="!ligne.lots || !ligne.lots.length"></td>
                <td v-if="!ligne.lots || !ligne.lots.length"></td>
                <td v-if="!ligne.lots || !ligne.lots.length"></td>
                <td v-if="!ligne.lots || !ligne.lots.length"></td>
                <td v-else colspan="5" class="td-table">
                  <table class="table table-bordered table-sm text-center">
                    <colgroup>
                      <col width="49">
                      <col width="80">
                      <col width="50">
                      <col width="100">
                      <col width="29">
                    </colgroup>
                    <tbody>
                      <tr v-for="(lot, iLot) in ligne.lots" :key="iLot" :class="[{'ligne-supprimee':ligne.quantite === 0}]">
                        <td class="input">
                          <div v-if="ligne.estLivree">{{lot.quantite}}</div>
                          <div v-else><InputNumber v-model="lot.quantite" typeFiltre="quantite" zeroSiVide="1" /></div>
                        </td>
                        <td class="input">
                          <div v-if="ligne.estLivree">{{lot.numero}}</div>
                          <div v-else><input type="text" v-model="lot.numero" style="width:100%"></div>
                        </td>
                        <td class="input">
                          <div v-if="ligne.estLivree" :title="!lot.dlc && 'Date de Durabilité Minimale'">{{lot.dlc ? "DLC" : "DDM"}}</div>
                          <div v-else>
                            <input type="checkbox" v-model="lot.dlc">
                          </div>
                        </td>
                        <td class="input">
                          <div v-if="ligne.estLivree">{{ formatHumanDate(lot.dlcDluo) }}</div>
                          <div v-else>
                            <v-date-picker class="date-picker" v-model="lot.dlcDluo" :popover="{ visibility: 'click' }">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input :value="inputValue ? inputValue : 'Choisir la date...'" v-on="inputEvents" />
                              </template>
                            </v-date-picker>
                          </div>
                        </td>
                        <td class="input">
                          <button v-if="pageActive" class="btn btn-primary btn-sm" size="sm" @click="supprimerLigneLot(ligne.lots, iLot)"><i class="fas fa-minus"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td class="input">
                  <button v-if="pageActive" class="btn btn-primary btn-sm" size="sm" @click="ajouterLot(ligne)"><i class="fas fa-plus"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarreActions from '@/components/Divers/BarreActions'
import InputNumber from '@/components/Inputs/InputNumber'
import BoutonAjoutProduit from '@/components/SuiviCommandes/BoutonAjoutProduit'
import BoutonAjoutIncident from '@/components/SuiviCommandes/BoutonAjoutIncident'
import RechercheAjoutProduit from '@/components/Divers/RechercheAjoutProduit'
import { mapGetters } from "vuex"
import { detailsCommande, receptionnerCommande } from '@/api/gpao/commandes'
import { showAlerts } from '@/mixins/alerts'
import { connecteData } from "@/mixins/connecteData"
import { formatageDate, formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
import { qtePCB, prixPCB, arrondiNombre } from '@/helpers/utils'
import { alerteDonneesModifiees } from "@/mixins/index"
import { constantesId } from "@/helpers/constantes"
import { required } from "vuelidate/lib/validators"
import { commande } from "@/mixins/commandes"

export default {
  name: "DetailCommandeFournisseur",
  mixins: [ connecteData, alerteDonneesModifiees, showAlerts, formatageDate, formatHumanDateAvecSlashes, commande],
  components: {
    InputNumber,
    BoutonAjoutIncident,
    BoutonAjoutProduit,
    RechercheAjoutProduit,
    BarreActions
  },
  data() {
    return {
      key: 1,
      pageActive: true,
      commandeFournisseur: {},
      idLigneSelectionnee: null,
      verrouillerNavigation: true,
      optionsIncident: [],
      optionsIncidentGlobal: [],
      optionsIncidentLigne: [],
      optionsTypesDeReclamations: [],
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          hidden: Affiche/masque le bouton (bool),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        ajouterArticles: {
          label: "Ajouter un article",
          methode(contexte) {
            contexte.$refs.modalAjoutProduit.show()
          }
        },
        declarerIncident: {
          label: "Déclarer un incident",
          methode(contexte) {
            contexte.$refs.modalAjoutIncidentGlobal.show(contexte.calculAvoirGeneral())
          }
        },
        ajouterLot: {
          label: "Ajouter un lot",
          disabled: true,
          methode(contexte) {
            let ligneSelectionnee = contexte.ligneSelectionnee
            if (ligneSelectionnee) {
              contexte.ajouterLot(ligneSelectionnee)
            }
          }
        },
        exporter: {
          label: "Exporter",
          methode(contexte) {
            contexte.commandeFournisseur.lignes.filter(l => l.raisonIncidentId).forEach(l => {
              l.raisonIncident = contexte.raisonIncident(l)
            })
            contexte.exporterReceptionCommande(contexte.commandeFournisseur.lignes, contexte.commandeFournisseur.commandeNom)
          }
        },
        enregistrerEnStock: {
          label: "Enregistrer en stock",
          disabled: true,
          class: "btn-success",
          methode(contexte) {
            contexte.enregistrerEnStock(contexte.commandeFournisseur)
          }
        },
        annuler: {
          label: "Annuler",
          class: "btn-danger",
          async methode(contexte) {
            let result = await contexte.alerteConfirmation("Annuler le contrôle à réception", "Vos modifications seront définitivement perdues.<br/>Souhaitez-vous annuler le contrôle à réception ?")
            if (result) {
              contexte.verrouillerNavigation = false
              contexte.$router.push({ name: "DetailCommande", params: { commandeId: contexte.commandeFournisseur.commandeId } })
            }
          }
        },
        retour: {
          label: "Retour",
          methode(contexte) {
            contexte.$router.push({ name: "DetailCommande", params: { commandeId: contexte.commandeFournisseur.commandeId } })
          }
        },
        // Appeler "this.actions.initialiser(this, activerActions)" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte, activerActions) {
          if (activerActions) {
            contexte.actions.barreGauche = [ contexte.actions.ajouterArticles, contexte.actions.declarerIncident, contexte.actions.ajouterLot ]
            contexte.actions.barreDroite = [ contexte.actions.annuler, contexte.actions.enregistrerEnStock ]
          } else {
            contexte.actions.barreGauche = [contexte.actions.exporter]
            contexte.actions.barreDroite = [ contexte.actions.retour ]
          }
        },
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        disable(actions, disabled) {
          let actionsATraiter = [ actions.enregistrerEnStock ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableLigneNonSelectionnee(actions, disabled) {
          let actionsATraiter = [ actions.ajouterLot ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        }
      }
    }
  },
  validations: {
    commandeFournisseur: {
      numeroBL: { required },
      dateLivraison: { required }
    }
  },
  computed: {
    ...mapGetters("fournisseurs", ["nomFournisseur"]),
    ...mapGetters("definitions", ["trouverIngredient"]),
    constantesId() {
      return constantesId
    },
    ligneSelectionnee() {
      return this.commandeFournisseur?.lignes?.find(l => this.idLignePourSelection(l) === this.idLigneSelectionnee)
    }
  },
  watch: {
    ligneSelectionnee() {
      this.actions.disableLigneNonSelectionnee(this.actions, !this.ligneSelectionnee)
    }
  },
  methods: {
    calculAvoirGeneral() {
      let av = 0;
      this.commandeFournisseur?.lignes?.forEach(element => {
        av += this.calculAvoir(element)
      });
      return arrondiNombre(av,3)
    },
    calculAvoir(ligne) {
      return Math.abs(arrondiNombre(arrondiNombre(this.ligneTotalPCBCommande(ligne),3) - arrondiNombre(this.ligneTotalLivre(ligne),3),3))
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les boutons de la barre d'actions
      methode(this)
    },
    arrondiNombre(valeur, dec) {
      return arrondiNombre(valeur, dec)
    },
    async chargerCommande(commandeId, fournisseurId) {
      this.$store.commit('uxHelpers/showSpinner')
      let response = await detailsCommande(commandeId)
      this.initCommandeFournisseur(response.data, fournisseurId)
      this.$store.commit('uxHelpers/hideSpinner')
    },
    initCommandeFournisseur(commande, fournisseurId) {
      this.commandeFournisseur = commande?.detailFournisseurs?.find(f => f.fournisseurId === fournisseurId) || {}
      this.commandeFournisseur.commandeNom = commande?.nom || ""
      this.commandeFournisseur.raisonIncidentId = this.commandeFournisseur.incident?.raisonIncidentId || ""
      this.commandeFournisseur.detailIncident = this.commandeFournisseur.incident?.detail || ""
      this.commandeFournisseur.typeReclamation = this.commandeFournisseur.reclamation?.type || ""
      this.commandeFournisseur.montantAvoir = this.commandeFournisseur.reclamation?.montantAvoir || null
      this.commandeFournisseur.dateRelivraisonSouhaitee = this.commandeFournisseur.reclamation?.dateRelivraisonSouhaitee || ""
      this.commandeFournisseur.lignes = commande?.lignes?.filter(l => !l.deleted && l.foId === fournisseurId) || []
      this.commandeFournisseur.lignes.forEach(l => {
        l.quantite = arrondiNombre(qtePCB((l.estLivree ? l.qteLivree : l.qDem), l.pcb), 3)
        l.puLivre = arrondiNombre(l.estLivree ? l.puLivre : l.pu)
        l.raisonIncidentId = l.incident?.raisonIncidentId || ""
        l.detailIncident = l.incident?.detail || ""
        l.typeReclamation = l.reclamation?.type || ""
        l.montantAvoir = l.reclamation?.montantAvoir || null
        l.dateRelivraisonSouhaitee = l.reclamation?.dateRelivraisonSouhaitee || ""
        // chaque ligne d'une commande receptionnée est automatiquement associée à un lot donc on masque les lots uniques vides
        l.lots = l.lots.length > 1 ? l.lots : l.lots.filter(lot => lot.numero || lot.dlcDluo)
      })
    },
    donneesSCommande(sCommande) {
      return {
        commandeId: sCommande.commandeId,
        fournisseurId: sCommande.fournisseurId,
        dateLivraisonSouhaitee: this.formatDateAvecTirets(sCommande.dateLivraisonSouhaitee),
        numeroBL: sCommande.numeroBL,
        dateLivraison: sCommande.dateLivraison,
        raisonIncidentId: sCommande.raisonIncidentId,
        detailIncident: sCommande.detailIncident,
        typeReclamation: sCommande.typeReclamation,
        montantAvoir: sCommande.montantAvoir,
        dateRelivraisonSouhaitee: sCommande.dateRelivraisonSouhaitee,
        lignes: sCommande.lignes?.filter(l => l.foId === sCommande.fournisseurId).map(el => {
          return {
            id: el.id || "",
            tarifId: el.tId,
            dAgap: el.dAgap,
            dFou: el.dFou,
            reference: el.Ref,
            qDem: el.qDem,
            quantite: el.quantite,
            uc: el.uc,
            pu: el.puLivre,
            raisonIncidentId: el.raisonIncidentId,
            detailIncident: el.detailIncident,
            typeReclamation: el.typeReclamation,
            dateRelivraisonSouhaitee: el.typeReclamation === "TR1" ? el.dateRelivraisonSouhaitee : null,
            montantAvoir: el.typeReclamation === "TR2" ? el.montantAvoir : null,
            lots: el.lots || []
          }
        }) || []
      }
    },
    idLignePourSelection(ligne) {
      // La sélection se fait sur l'ID produit / ID fournisseur pour avoir l'unicité (car l'ID de ligne n'est pas renseigné lors de l'ajout d'une nouvelle ligne dans la commande)
      return (ligne.pId || "") + (ligne.foId || "")
    },
    selectionnerLigneCommande(ligne) {
      if (ligne) {
        this.idLigneSelectionnee = null
      }
      this.idLigneSelectionnee = this.idLignePourSelection(ligne)
    },
    fournisseurNbLignes(lignes) {
      return lignes?.length || 0
    },
    ligneTotalPCBCommande(ligne) {
      return prixPCB(arrondiNombre(ligne.qDem,3), arrondiNombre(ligne.pcb,3), arrondiNombre(ligne.pu,3))
    },
    ligneTotalLivre(ligne) {
      return arrondiNombre(ligne.qteLivree || ligne.quantite,3) * arrondiNombre(ligne.puLivre,3)
    },
    fournisseurTotalLivre(lignes) {
      return lignes?.reduce((s, l) => s + this.ligneTotalLivre(l), 0) || 0
    },
    async receptionnerCommande(sCommande) {
      await receptionnerCommande(sCommande.commandeId, this.donneesSCommande(sCommande))
      this.alerteSucces('Commande réceptionnée !')
      this.verrouillerNavigation = false
      this.$router.push({ name: "DetailCommande", params: { commandeId: sCommande.commandeId } })
    },
    rafraichirAffichage() {
      let idLigneSelectionnee = this.idLigneSelectionnee
      this.idLigneSelectionnee = null
      this.idLigneSelectionnee = idLigneSelectionnee
    },
    ajouterArticle(sCommande, event) {
      sCommande.lignes.unshift({
        tId: event.tarifId,
        foId: event.article.fournisseurId,
        dAgap: event.article.designation,
        dFou: event.article.designation,
        reference: event.article.reference,
        uc: event.article.uc,
        qDem: 0,
        quantite: event.quantite,
        pu: 0,
        puLivre: event.article.prixUnitaire,
        lots: []
      })
      this.rafraichirAffichage()
      this.key += 1
    },
    substituerArticle(ligne, event) {
      ligne.ancienneReference = ligne.reference
      ligne.ancienneDesignation = this.designation(ligne.foId, ligne.dAgap, ligne.dFou)
      ligne.ancienTarifId = ligne.tId
      ligne.tId = event.article.tarifId
      ligne.foId = event.article.fournisseurId
      ligne.dAgap = event.article.designation
      ligne.dFou = event.article.designation
      ligne.reference = event.article.reference
      if (event.article.pu < ligne.pu) {
        ligne.pu = event.article.pu
      }
      this.rafraichirAffichage()
    },
    retirerArticle(sCommande, index) {
      sCommande.lignes.splice(index, 1)
    },
    ajouterLot(ligne) {
      let qteDem = 0
      if (ligne.lots.length === 0) {
        qteDem = ligne.quantite
      }
      ligne.lots.push({
        numero: "",
        quantite: qteDem,
        dlc: true,
        dlcDluo: ""
      })
      this.rafraichirAffichage()
    },
    supprimerLigneLot(lots, iLot) {
      lots.splice(iLot, 1)
      this.rafraichirAffichage()
    },
    ouvrirAjoutIncidentLigne(ligne) {
      this.idLigneSelectionnee = this.idLignePourSelection(ligne)
      this.$refs.modalAjoutIncidentLigne.show(this.calculAvoir(ligne))
    },
    ajouterIncident(event, el) {
      this.$set(el, 'raisonIncidentId', event.raisonIncidentId)
      this.$set(el, 'typeReclamation', event.typeReclamation)
      this.$set(el, 'dateRelivraisonSouhaitee', event.dateRelivraisonSouhaitee)
      this.$set(el, 'montantAvoir', event.montantAvoir)
      this.$set(el, 'detailIncident', event.detailIncident)
      this.rafraichirAffichage()
    },
    enleverIncident(el) {
      this.$set(el, 'raisonIncidentId', "")
      this.$set(el, 'typeReclamation', "")
      this.$set(el, 'dateRelivraisonSouhaitee', "")
      this.$set(el, 'montantAvoir', "")
      this.$set(el, 'detailIncident', "")
      this.rafraichirAffichage()
    },
    trouverLigneCommande(sens) {
      if (!this.commandeFournisseur?.lignes?.length || !sens) {
        return null
      }

      let ligne = null
      let iLigne = this.commandeFournisseur.lignes.findIndex(l => this.idLignePourSelection(l) === this.idLigneSelectionnee) + sens

      if (iLigne < 0) {
        iLigne = this.commandeFournisseur.lignes.length - 1
      } else if (iLigne >= this.commandeFournisseur.lignes.length) {
        iLigne = 0
      }
      ligne = this.commandeFournisseur.lignes[iLigne]

      return ligne
    },
    deplacerLigne(sens) {
      let iSens = sens.direction === "up" ? -1 : sens.direction === "down" || sens.direction === "enter" ? 1 : 0
      let ligne = this.trouverLigneCommande(iSens)
      if (ligne) {
        this.selectionnerLigneCommande(ligne)
      }
    },
    deplacerQteLivree(sens) {
      if (sens.direction === "left" || sens.direction === "right") {
        if (this.idLigneSelectionnee) {
          document.getElementById('puLivre' + this.idLigneSelectionnee)?.focus()
        }
        return
      }
      this.deplacerLigne(sens)
      if (this.idLigneSelectionnee) {
        document.getElementById('qteLivree' + this.idLigneSelectionnee)?.focus()
      }
    },
    deplacerPULivre(sens) {
      if (sens.direction === "left" || sens.direction === "right") {
        if (this.idLigneSelectionnee) {
          document.getElementById('qteLivree' + this.idLigneSelectionnee)?.focus()
        }
        return
      }
      this.deplacerLigne(sens)
      if (this.idLigneSelectionnee) {
        document.getElementById('puLivre' + this.idLigneSelectionnee)?.focus()
      }
    },

    nomIncident(raisonIncidentId) {
      return this.optionsIncident.find(el => raisonIncidentId === el.code).designation
    },
    nomTypeReclamation(typeReclamationId) {
      var reclamation = this.optionsTypesDeReclamations.find(el => typeReclamationId === el.code)
      return reclamation && reclamation.designation || typeReclamationId
    },
    detailReclamation(elt) {
      var reclamation = elt.montantAvoir ? elt.montantAvoir + " € HT." : ""
      reclamation += elt.dateRelivraisonSouhaitee ? this.formatHumanDate(elt.dateRelivraisonSouhaitee) + "." : ""
      return this.nomTypeReclamation(elt.typeReclamation) + " : " + reclamation
    },
    raisonIncident(elt) {
      var detailIncident = this.nomIncident(elt.raisonIncidentId) + "."
      detailIncident += elt.typeReclamation ? " " + this.detailReclamation(elt) : ""
      detailIncident += elt.detailIncident ? " \"" + elt.detailIncident + "\"" : ""
      return detailIncident
    },
    async enregistrerEnStock(sCommande) {
      let result = await this.alerteConfirmation('Enregistrer en stock ?', "Souhaitez vous enregistrer cette commande en stock ?")
      if (result) {
        this.receptionnerCommande(sCommande)
      }
    },
    setDateLivraisonSCommande(sCommande, date) {
      if (sCommande.dateLivraison !== date) {
        sCommande.dateLivraison = date
      }
      this.actions.disable(this.actions, !this.commandeFournisseur.numeroBL || !this.commandeFournisseur.dateLivraison)
    },
    designation(foId, dAgap, dFou) {
      if (foId) {
        return dFou
      } else {
        return dAgap
      }
    },
    dateLivraison(sCommande) {
      return this.formatHumanDateAvecSlashes(sCommande.dateLivraison || sCommande.dateLivraisonSouhaitee)
    }
  },
  async mounted() {
    this.optionsIncident = this.$store.state.definitions.raisonsIncidents || []
    this.optionsIncidentGlobal = this.optionsIncident.filter(el => el.porteSur === 'Commande')
    this.optionsIncidentLigne = this.optionsIncident.filter(el => el.porteSur === 'Ligne')
    this.optionsTypesDeReclamations = this.$store.state.definitions.typesReclamations
    this.aujourdhui = new Date()

    await this.chargerCommande(this.$route.params.commandeId, Number(this.$route?.params?.fournisseurId || "0"))
    // Les actions de la page sont disponible uniquement si la commande fournisseur affichée est bien à réceptionner
    this.pageActive = this.commandeFournisseur.etatCommande === constantesId.etatCmdEnvoyee
    this.actions.initialiser(this, this.pageActive)
    this.verrouillerNavigation = this.pageActive
  }
}
</script>
