<template>
  <b-modal ref="modalEditionLieuPortage" size="lg" title="Paramétrage du lieu de portage"
    no-close-on-backdrop scrollable>
    <template slot="default">
      <div v-if="lieuEnCours" class="container">
        <div class="row">
          <div class="col-10">
            <div class="form-group">
              <label :for="'libelle' + lieuEnCours.id">Libellé</label>
              <input @focus="$event.target.select()" class="form-control" :id="'libelle' + lieuEnCours.id" type="text" v-model="lieuEnCours.libelle">
              <div class="error" v-if="!nomValide">Ce champ est requis</div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <label>Actif</label>
              <ToggleButton v-model="lieuEnCours.actif" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label :for="'adresse1' + lieuEnCours.id">Adresse</label>
          <input @focus="$event.target.select()" class="form-control" :id="'adresse1' + lieuEnCours.id" type="text" v-model="lieuEnCours.adresse1">
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label :for="'adresse2' + lieuEnCours.id">Complément d'adresse 1</label>
              <input @focus="$event.target.select()" class="form-control" :id="'adresse2' + lieuEnCours.id" type="text" v-model="lieuEnCours.adresse2">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label :for="'adresse3' + lieuEnCours.id">Complément d'adresse 2</label>
              <input @focus="$event.target.select()" class="form-control" :id="'adresse3' + lieuEnCours.id" type="text" v-model="lieuEnCours.adresse3">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <div class="form-group">
              <label :for="'codePostal' + lieuEnCours.id">Code postal</label>
              <input @focus="$event.target.select()" class="form-control" :id="'codePostal' + lieuEnCours.id" type="text" v-model="lieuEnCours.codePostal">
            </div>
          </div>
          <div class="col-7">
            <div class="form-group">
              <label :for="'ville' + lieuEnCours.id">Ville</label>
              <input @focus="$event.target.select()" class="form-control" :id="'ville' + lieuEnCours.id" type="text" v-model="lieuEnCours.ville">
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label :for="'separation' + lieuEnCours.id" title="Sur les bons de livraison, on sépare par régime et texture">Séparation sur BL</label>
              <ToggleButton v-model="lieuEnCours.regimesSepares" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" :key="lieuEnCours.id"/>
            </div>
          </div>
          <div class="col-10">
            <div class="form-group">
              <label>Jours d'ouverture</label>
              <div>
                <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(1)" @change="jourChanged(1, $event)"><span class="ml-2">Lundi</span></label>
                <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(2)" @change="jourChanged(2, $event)"><span class="ml-2">Mardi</span></label>
                <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(3)" @change="jourChanged(3, $event)"><span class="ml-2">Mercredi</span></label>
                <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(4)" @change="jourChanged(4, $event)"><span class="ml-2">Jeudi</span></label>
                <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(5)" @change="jourChanged(5, $event)"><span class="ml-2">Vendredi</span></label>
                <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(6)" @change="jourChanged(6, $event)"><span class="ml-2">Samedi</span></label>
                <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(0)" @change="jourChanged(0, $event)"><span class="ml-2">Dimanche</span></label>
              </div>
            </div>
          </div>
          <div class="col-2">
            <BoutonGestionBarquettes :donnees="lieuEnCours.donneesSupplementaires" :dropLeft="true" @enregistrer="enregistrerBarquettesLieu" />
          </div>
        </div>

        <div v-for="(effectif, iEffectif) in effectifsEnCours" :key="iEffectif">
          <div class="card border-secondary">
            <div :class="'card-header flex-center-space-between ' + (estDoublon(effectif) ? 'bg-danger' : '')">
              <span>{{ afficherTexteAEffectif(effectif) }}</span>
              <button type="button" class="btn btn-danger btn-sm" v-tooltip="{ content: 'Supprimer cet effectif'}" @click="supprimerEffectif(effectif)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <div class="card-body">
              <div v-if="estDoublon(effectif)" class="error pt-2">Il existe déjà une configuration identique (même structure de menu, même service, même régime et même texture)</div>
              <div class="row">
                <div class="col-10">
                  <div class="form-group">
                    <label>Structure de menu</label>
                    <b-form-select v-model="effectif.structureMenuId" :options="structures" value-field="id" text-field="nom">
                      <template slot="first">
                        <option :value="null" disabled>Structure de menu...</option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="error" v-if="!effectif.structureMenuId">Ce champ est requis</div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label>Plat témoin</label>
                    <ToggleButton v-model="effectif.aPlatTemoin" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-2">
                  <div class="form-group">
                    <label>Effectif</label>
                    <InputNumber v-model="effectif.effectif" typeFiltre="quantiteEntiere" style="width:100%;" title="Effectif" />
                  </div>
                </div>
                <div class="col-8">
                  <div class="form-group">
                    <label>Service</label>
                    <b-form-select v-model="effectif.serviceId" :options="optionsServices" v-tooltip="{ content: 'Sans sélection manuelle, tous les services sont sélectionnés', placement: 'top' }">
                      <template slot="first">
                        <option :value="null" disabled>Service...</option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <div class="col-2">
                  <BoutonGestionBarquettes :donnees="effectif.donneesSupplementaires" :dropLeft="true" @enregistrer="enregistrerBarquettesEffectif(effectif, $event)" />
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>Régime</label>
                    <b-form-select v-model="effectif.regimeId" :options="optionsRegimes" style="margin-top:3px;">
                      <template slot="first">
                        <option :value="null" disabled>Régime...</option>
                      </template>
                    </b-form-select>
                    <div class="error" v-if="!effectif.regimeId">Ce champ est requis</div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Texture</label>
                      <b-form-select v-model="effectif.textureId" :options="optionsTextures" style="margin-top:3px;">
                      <template slot="first">
                        <option :value="null" disabled>Texture...</option>
                      </template>
                    </b-form-select>
                    <div class="error" v-if="!effectif.textureId">Ce champ est requis</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="error" v-if="effectifsEnCours.length === 0">
          Il faut indiquer au moins un effectif
        </div>
      </div>
    </template>
    <template slot="modal-footer">
      <div class="flex-center-space-between w-100">
      <button type="button" class="btn btn-primary" :disabled="!lieuValide" @click="addEffectif(lieuEnCours)">Ajouter un effectif</button>
      <div>
      <button type="button" class="btn btn-secondary mr-2" @click="annulerEdition">Annuler</button>
      <button type="button" class="btn btn-primary" :disabled="!lieuValide" @click="enregistrerLieu">Enregistrer</button>
      </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex"
import InputNumber from "@/components/Inputs/InputNumber"
import BoutonGestionBarquettes from "@/components/Divers/BoutonGestionBarquettes"
import _ from 'lodash'

export default {
  name: "EditionLieuPortage",
  props: { "lieuDePortage": Object, "structures": Array },
  components: { InputNumber, BoutonGestionBarquettes },
  mixins: [],
  data() {
    return {
      keyLieu: 1,
      lieuEnCours: null
    }
  },
  computed: {
    ...mapGetters("definitions", ["convivesTypesNonNeutre", "regimes", "textures", "services"]),
    optionsConviveType() { return this.convivesTypesNonNeutre },
    optionsRegimes() { return this.regimes },
    optionsTextures() { return this.textures },
    optionsServices() { return this.services },
    effectifsEnCours() {
      return this.lieuEnCours?.effectifs?.filter(x => x.effectif !== -1) || []
    },
    nomValide() {
      return this.lieuEnCours?.libelle?.length > 3
    },
    lieuValide() {
      if (!this.nomValide)
        return false  // Nom du lieu invalide

      if (this.effectifsEnCours.length === 0)
        return false

      if (this.effectifsEnCours.some(e => !e.structureMenuId || !e.textureId || !e.regimeId || this.estDoublon(e)))
        return false  // Au moins un effectif invalide ici
 
      return true
    }
  },
  methods: {
    show(lieu) {
      if (!lieu)
        return
      
      this.lieuEnCours = {...lieu}
      this.checkLieu()
      this.$refs.modalEditionLieuPortage.show()
    },
    hide() {
      this.$refs.modalEditionLieuPortage.hide()
    },
    supprimerEffectif(effectif) {
      effectif.effectif = -1
    },
    addEffectif() {
      this.lieuEnCours.effectifs = this.lieuEnCours.effectifs || []
      this.lieuEnCours.effectifs.push({ structureMenuId: null, regimeId: null, textureId: null, effectif: 0, serviceId: null })
    },
    checkLieu() {
      if (!this.lieuEnCours.joursOuverture) {
        // Initialisation de la liste des jours
        this.lieuEnCours.joursOuverture = []
      }
      if (!this.lieuEnCours.effectifs?.length) {
        // Initialisation de la liste des effectifs
        this.addEffectif()
      } else {
        // Mise en forme correcte des régimes et textures avec de ne pas avoir de valeur vide dans les configuration
        // => permet de corriger les anciennes configurations créées avant la mise en place des contrôles de saisie
        let regimeNormalId = [...this.regimes].sort((t1, t2) => t1.ordre - t2.ordre).first()?.value
        let textureNormaleId = [...this.textures].sort((t1, t2) => t1.ordre - t2.ordre).first()?.value
        if (!regimeNormalId && !textureNormaleId) {
          return
        }
        this.lieuEnCours.effectifs.forEach(e => {
          if (!e.regimeId && regimeNormalId) {
            e.regimeId = regimeNormalId
          }
          if (!e.textureId && textureNormaleId) {
            e.textureId = textureNormaleId
          }
        })
      }
    },
    enregistrerLieu() {
      this.$emit("enregistrer-lieu", this.lieuEnCours)
    },
    annulerEdition() {
      this.$emit("annuler-edition")
    },
    enregistrerBarquettesLieu(dataBarquettes) {
      var data = this.lieuEnCours.donneesSupplementaires && JSON.parse(this.lieuEnCours.donneesSupplementaires.replaceAll("'", "\"")) || {}
      data.Barquettage = dataBarquettes
      this.lieuEnCours.donneesSupplementaires = JSON.stringify(data).replaceAll("\"", "'")
    },
    enregistrerBarquettesEffectif(effectif, dataBarquettes) {
      var data = effectif.donneesSupplementaires && JSON.parse(effectif.donneesSupplementaires.replaceAll("'", "\"")) || {}
      data.Barquettage = dataBarquettes
      effectif.donneesSupplementaires = JSON.stringify(data).replaceAll("\"", "'")
    },
    jourChanged(jour, ouvert) {
      if (ouvert.target.checked) {
        this.lieuEnCours.joursOuverture.push(jour)
      } else {
        _.remove(this.lieuEnCours.joursOuverture, e => e === jour)
      }
    },
    afficherTexteAEffectif(effectif) {
      if (!effectif)
        return ""
      
      let informations = [
        (effectif.structure !== "" ? (effectif.structure + " x" + effectif.effectif) : ""),
        effectif.service,
        effectif.regime === "Normal" ? "" : effectif.regime,
        effectif.texture === "Normal" ? "" : effectif.texture
      ]

      return informations.filter(i => i).join(" - ")
    },
    estDoublon(effectif) {
      let doublons = this.effectifsEnCours.filter(e => e.structureMenuId === effectif.structureMenuId && e.regimeId === effectif.regimeId
        && e.textureId === effectif.textureId && e.serviceId === effectif.serviceId) || []
      return doublons.length > 1
    }
  }
}

</script>
