<template>
  <div class="container-fluid">
    <EditionLieuPortage ref="editionLieuPortage" :lieuDePortage="enEditionLieu" :structures="structures" @enregistrer-lieu="enregistrerLieu($event)" @annuler-edition="fermerModalEdition()" />

    <ul class="nav nav-tabs nav-linetriangle">
      <li class="nav-item">
        <a class="nav-link active show" id="active-tab" data-toggle="tab" href="#Etablissement" aria-controls="active" role="tab" aria-selected="true">Établissement</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="link-tab" data-toggle="tab" href="#LieuxLivraison" aria-controls="link" role="tab" aria-selected="false">Lieux de portage</a>
      </li>
    </ul>
    <div class="tab-content">

      <div class="tab-pane active" id="Etablissement">
        <div class="row">
          <!-- Caché en attendant de trouver comment faire le lien avec l'ERP
            <div class="col-md-4 col-sm-12">
            <div class="card">
              <div class="card-header bg-gradient-bleu text-white">
                Personnalisation
              </div>
              <div class="card-body">
                <fieldset class="form-group">
                  <label :for="'logo' + currentEtab.eId">Photo ou logo de l'établissement</label>
                  <div class="img-wrapper">
                    <img v-if="currentEtab.logo" :id="'logo' + currentEtab.eId" class="img-logo" :src="currentEtab.logo">
                    <div v-else :id="'logo' + currentEtab.eId" class="img-logo"></div>
                    <div class="img-overlay">
                      <button class="btn bleuAgap" v-tooltip="{content: 'Ajouter un logo'}" style="transform:scale(0.7);border-radius:20px;"><i class="fas fa-plus" style="transform:scale(1.6);" /></button>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="form-group">
                  <label :for="'gerant' + currentEtab.eId">Gérant(e) de l'établissement</label>
                  <input @focus="$event.target.select()" class="form-control" :id="'gerant' + currentEtab.eId" type="text" v-model="currentEtab.gerant">
                </fieldset>
                <fieldset class="form-group">
                  <label :for="'chef' + currentEtab.eId">Chef de cuisine</label>
                  <input @focus="$event.target.select()" class="form-control" :id="'chef' + currentEtab.eId" type="text" v-model="currentEtab.chef">
                </fieldset>
              </div>
            </div>
          </div> -->

          <div class="col-lg-6 col-md-12">
            <div class="card">
              <div class="card-header bg-gradient-bleu text-white">
                Coordonnées
              </div>
              <div class="card-body row">
                <fieldset class="form-group col-sm-8">
                  <label :for="'RS' + currentEtab.eId">Raison Sociale</label>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'RS' + currentEtab.eId" type="text" v-model="currentEtab.eRS">
                  <div class="error" v-if="!$v.currentEtab.eRS.required">
                    Ce champ est requis
                  </div>
                  <div class="error" v-else-if="!$v.currentEtab.eRS.maxLength">
                    Ce champ est trop long (max. {{$v.currentEtab.eRS.$params.maxLength.max}} caractères)
                  </div>
                </fieldset>
                <fieldset class="form-group col-sm-4" v-show="profilIsAdmin">
                  <label :for="'AF' + currentEtab.eId">Code AF</label>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'AF' + currentEtab.eId" type="text" v-model="currentEtab.codeAF">
                </fieldset>

                <fieldset class="form-group col-sm-8">
                  <label :for="'adresse1' + currentEtab.eId">Adresse</label>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'adresse1' + currentEtab.eId" type="text" v-model="currentEtab.adresse1">
                  <div class="error" v-if="!$v.currentEtab.adresse1.maxLength">
                    Ce champ est trop long (max. {{$v.currentEtab.adresse1.$params.maxLength.max}} caractères)
                  </div>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'adresse2' + currentEtab.eId" type="text" v-model="currentEtab.adresse2">
                  <div class="error" v-if="!$v.currentEtab.adresse2.maxLength">
                    Ce champ est trop long (max. {{$v.currentEtab.adresse2.$params.maxLength.max}} caractères)
                  </div>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'adresse3' + currentEtab.eId" type="text" v-model="currentEtab.adresse3">
                  <div class="error" v-if="!$v.currentEtab.adresse3.maxLength">
                    Ce champ est trop long (max. {{$v.currentEtab.adresse3.$params.maxLength.max}} caractères)
                  </div>
                </fieldset>
                <div class="col-sm-4">
                  <fieldset class="form-group">
                    <label :for="'telephone' + currentEtab.eId">Téléphone</label>
                    <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'telephone' + currentEtab.eId" type="text" v-model="currentEtab.tel">
                    <div class="error" v-if="!$v.currentEtab.tel.maxLength">
                      Ce champ est trop long (max. {{$v.currentEtab.tel.$params.maxLength.max}} caractères)
                    </div>
                  </fieldset>
                  <fieldset class="form-group">
                    <label :for="'email' + currentEtab.eId">Email</label>
                    <input @focus="$event.target.select()" @input="hasMods = true;checkMail()" class="form-control" :id="'email' + currentEtab.eId" type="text" v-model="tmpMail">
                    <div class="error" v-if="erreurMail">
                      Adresse email invalide
                    </div>
                    <div class="error" v-else-if="!$v.tmpMail.maxLength">
                      Ce champ est trop long (max. {{$v.tmpMail.$params.maxLength.max}} caractères)
                    </div>
                  </fieldset>
                </div>

                <fieldset class="form-group col-sm-3">
                  <label :for="'CP' + currentEtab.eId">Code Postal</label>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'CP' + currentEtab.eId" type="text" v-model="currentEtab.codePostal">
                  <div class="error" v-if="!$v.currentEtab.codePostal.required">
                    Ce champ est requis
                  </div>
                  <div class="error" v-if="!$v.currentEtab.codePostal.numeric">
                    Ce champ doit être un nombre
                  </div>
                  <div class="error" v-else-if="!$v.currentEtab.codePostal.minLength">
                    Ce champ est trop court (min. {{$v.currentEtab.codePostal.$params.minLength.min}} caractères)
                  </div>
                  <div class="error" v-else-if="!$v.currentEtab.codePostal.maxLength">
                    Ce champ est trop long (max. {{$v.currentEtab.codePostal.$params.maxLength.max}} caractères)
                  </div>
                </fieldset>
                <fieldset class="form-group col-sm-5">
                  <label :for="'ville' + currentEtab.eId">Ville</label>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'ville' + currentEtab.eId" type="text" v-model="currentEtab.ville">
                  <div class="error" v-if="!$v.currentEtab.ville.maxLength">
                    Ce champ est trop long (max. {{$v.currentEtab.ville.$params.maxLength.max}} caractères)
                  </div>
                </fieldset>
                <fieldset class="form-group col-sm-4">
                  <label :for="'contact' + currentEtab.eId">Contact</label>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'contact' + currentEtab.eId" type="text" v-model="currentEtab.contact">
                </fieldset>
                <fieldset class="form-group col-lg-12 col-md-12">
                  <label :for="'commentaire' + currentEtab.eId">Commentaire</label>
                  <textarea @focus="$event.target.select()" @input="hasMods = true" class="form-control" rows="4" :id="'commentaire' + currentEtab.eId" style="width:100%;" v-model="currentEtab.commentairePourToutFournisseur"></textarea>
                </fieldset>
                
                <div class="col-sm-12" style="text-align:right;">
                  <b-button class="btn vertAgap" @click="saveEtab" :disabled="erreurMail || !hasMods" title="Sauvegarder cet établissement" alt="Enregistrer">Enregistrer</b-button>
                </div>
              </div>
            </div>
          </div>

          
          <div class="col-lg-6 col-md-12">
            <div class="card">
              <div class="card-header">
                Liste des Contacts
              </div>
              <table class="table table-bordered table-sm text-center">
                <thead class="table-header-secondary">
                  <th>Contact</th>
                  <th>Fonction</th>
                  <th>Email</th>
                  <th>Tel</th>
                </thead>
                <tbody>
                    <tr v-for="(contact, id) in utilisateurComplet.personnes" :key="id">
                      <td style="vertical-align: middle">
                        {{contact.prenom + " " + contact.nom}}
                      </td>
                      <td>
                        {{contact.fonction}}
                      </td>
                      <td style="vertical-align: middle">
                        {{contact.email}}
                      </td>
                      <td style="vertical-align: middle">
                        {{contact.tel}}
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
          
          <div class="w-100"></div>

          <div class="col-lg-6 col-md-12">
            <div class="card">
              <div class="card-header">
                Production et portage
              </div>
              <div class="card-body row">
                <fieldset class="form-group col-lg-9 col-md-6 col-sm-12">
                  <label :for="'Agrement' + currentEtab.eId" v-tooltip="{ content: 'Numéro sans la mention FR/CE.\nExemple : 33.535.999'}">Agrément sanitaire</label>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'Agrement' + currentEtab.eId" maxlength="11" type="text" v-model="currentEtab.objDS.NUMAGREMENT" placeholder="Ex : 33.535.999">
                </fieldset>
                <fieldset class="form-group col-lg-3 col-md-6 col-sm-12">
                  <label :for="'EtablissementDlcPortage' + currentEtab.eId" v-tooltip="{ content: 'Valeur en J+ jours de la DLC souhaitée. (J+3 par défaut si champs vide).'}">DLC par défaut</label>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'EtablissementDlcPortage' + currentEtab.eId" type="number" step="1" v-model="currentEtab.objDS.EtablissementDlcPortage">
                </fieldset>

                <h4 class="form-section col-sm-12" style="margin:15px 0;"><i class="icone-mise-en-production"></i> Fiches de production</h4>

                <fieldset class="form-group col-sm-12">
                  <label v-tooltip="{ content: 'Ajoute aux fiches de production un récaptitulatif de la répartition des recettes par lieu de portage.'}">Intégrer la répartition par lieu de portage</label>
                  <fieldset>
                  <div class="d-inline-block custom-control custom-radio mr-1">
                    <input type="radio" class="custom-control-input" name="repartprod" value="non" id="repartprod-non" v-model="currentEtab.objDS.RepartitionProdParLieu" @input="hasMods = true">
                    <label class="custom-control-label" for="repartprod-non">Non</label>
                  </div>
                  <div class="d-inline-block custom-control custom-radio mr-1">
                    <input type="radio" class="custom-control-input" name="repartprod" value="ouifin" id="repartprod-ouifin" v-model="currentEtab.objDS.RepartitionProdParLieu" @input="hasMods = true">
                    <label class="custom-control-label" for="repartprod-ouifin">Oui, en fin de document</label>
                  </div>
                  <div class="d-inline-block custom-control custom-radio mr-1">
                    <input type="radio" class="custom-control-input" name="repartprod" value="ouiintegre" id="repartprod-ouiintegre" v-model="currentEtab.objDS.RepartitionProdParLieu" @input="hasMods = true">
                    <label class="custom-control-label" for="repartprod-ouiintegre">Oui, dans chaque recette</label>
                  </div>
                  </fieldset>
                </fieldset>

                <h4 class="form-section col-sm-12" style="margin:15px 0;"><i class="fas fa-shipping-fast"></i> Bons de livraison</h4>

                <fieldset class="form-group col-lg-4 col-md-6 col-sm-12">
                  <label :for="'BLDoubleExemplaire' + currentEtab.eId" v-tooltip="{ content: 'Duplique les bons de livraisons en une version `Cuisine` et une `Client`.'}">Double exemplaire</label><br />
                  <ToggleButton v-model="currentEtab.objDS.BLDoubleExemplaire" :key="currentEtab.objDS.BLDoubleExemplaire" @input="hasMods = true" :id="'BLDoubleExemplaire' + currentEtab.eId" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" />
                </fieldset>
                <fieldset class="form-group col-lg-4 col-md-6 col-sm-12">
                  <label :for="'BLControleDetaille' + currentEtab.eId" v-tooltip="{ content: 'Si à Oui, affiche un contrôle détaillé pour chaque recette du BL.'}">Contrôle détaillé par recette</label><br />
                  <ToggleButton v-model="currentEtab.objDS.BLControleDetaille" :key="currentEtab.objDS.BLControleDetaille" @input="hasMods = true" :id="'BLControleDetaille' + currentEtab.eId" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" />
                </fieldset>
                <fieldset class="form-group col-lg-4 col-md-6 col-sm-12">
                  <label :for="'BLAfficherDouteAllergene' + currentEtab.eId" v-tooltip="{ content: 'Affiche dans les bons de livraison `????` en cas de doute sur les allergènes.'}">Afficher le doute sur allergène</label><br />
                  <ToggleButton v-model="currentEtab.objDS.BLAfficherDouteAllergene" :key="currentEtab.objDS.BLAfficherDouteAllergene" @input="hasMods = true" :id="'BLAfficherDouteAllergene' + currentEtab.eId" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" />
                </fieldset>
                <div class="w-100"></div>
                <fieldset class="form-group col-lg-4 col-md-6 col-sm-12">
                  <label :for="'BLAfficherEffectifVide' + currentEtab.eId" v-tooltip="{ content: 'Si à Oui, les lieux sans effectifs sont tout de même générés dans les bons de livraison.'}">Imprimer les lieux sans effectif</label><br />
                  <ToggleButton v-model="currentEtab.objDS.BLAfficherEffectifVide" :key="currentEtab.objDS.BLAfficherEffectifVide" @input="hasMods = true" :id="'BLAfficherEffectifVide' + currentEtab.eId" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" />
                </fieldset>
                <fieldset class="form-group col-lg-4 col-md-6 col-sm-12">
                  <label :for="'BLAfficherEtiquettes' + currentEtab.eId" v-tooltip="{ content: 'Affiche les étiquettes dans les bons de livraison de portage.'}">&Eacute;tiquettes au verso (format spécifique)</label><br />
                  <ToggleButton v-model="currentEtab.objDS.BLAfficherEtiquettes" :key="currentEtab.objDS.BLAfficherEtiquettes" @input="hasMods = true" :id="'BLAfficherEtiquettes' + currentEtab.eId" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" />
                </fieldset>

                <h4 class="form-section col-sm-12" style="margin:15px 0;"><i class="fas fa-temperature-low"></i> Enregistrement du refroidissement</h4>

                <h6 class="form-section col-sm-12" style="margin:0 0 15px 0;">Personnalisation des valeurs sur l'enregistrement de refroidissement.</h6>

                <fieldset class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label :for="'RefroidissementValeur' + currentEtab.eId" v-tooltip="{ content: 'Valeur par défaut : 2h maximum entre +63°C et +3°C à coeur.\nMaximum 50 caractères.' }">Valeur retenue</label>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'RefroidissementValeur' + currentEtab.eId" type="text" v-model="currentEtab.objDS.RefroidissementValeur" 
                         placeholder="Ex : 2h maximum entre +63°C et +3°C à coeur" maxlength="50" />
                </fieldset>
                <fieldset class="form-group col-lg-6 col-md-6 col-sm-12">
                  <label :for="'RefroidissementTolerance' + currentEtab.eId" v-tooltip="{ content: 'Valeur par défaut : Jusqu\'à 2h de la fin de cuisson à +10°C.\nMaximum 50 caractères.' }">Tolérance admise</label>
                  <input @focus="$event.target.select()" @input="hasMods = true" class="form-control" :id="'RefroidissementTolerance' + currentEtab.eId" type="text" v-model="currentEtab.objDS.RefroidissementTolerance" 
                         placeholder="Ex : Jusqu'à 2h de la fin de cuisson à +10°C" maxlength="50" />
                </fieldset>
                <div class="w-100"></div>
                <fieldset class="form-group col-lg-12 col-md-12 col-sm-12">
                  <label :for="'RefroidissementAction1' + currentEtab.eId" v-tooltip="{ content: 'Liste des actions correctives en cas de dépassement de la tolérance.\nMaximum 60 caractères par ligne.' }">Actions correctives en cas de dépassement de la tolérance</label>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text" style="border: 0;box-shadow: inset 0 -1px 0px rgba(0, 0, 0, 0.25);background: rgba(0, 0, 0, 0.05);border-radius: 0;">Ligne 1</span></div>
                    <input @focus="$event.target.select()" @input="hasMods = true" class="form-control pl-2" :id="'RefroidissementAction1' + currentEtab.eId" type="text" v-model="currentEtab.objDS.RefroidissementAction1" 
                          placeholder="Ex : Nouvelle prise de température" maxlength="60" />
                  </div>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text" style="border: 0;box-shadow: inset 0 -1px 0px rgba(0, 0, 0, 0.25);background: rgba(0, 0, 0, 0.05);border-radius: 0;">Ligne 2</span></div>
                    <input @focus="$event.target.select()" @input="hasMods = true" class="form-control pl-2" :id="'RefroidissementAction2' + currentEtab.eId" type="text" v-model="currentEtab.objDS.RefroidissementAction2" 
                          placeholder="Ex : Destruction des lots" maxlength="60" />
                  </div>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text" style="border: 0;box-shadow: inset 0 -1px 0px rgba(0, 0, 0, 0.25);background: rgba(0, 0, 0, 0.05);border-radius: 0;">Ligne 3</span></div>
                    <input @focus="$event.target.select()" @input="hasMods = true" class="form-control pl-2" :id="'RefroidissementAction3' + currentEtab.eId" type="text" v-model="currentEtab.objDS.RefroidissementAction3" 
                          placeholder="Ex : Mise en place d'un menu de substitution" maxlength="60" />
                  </div>
                  <div class="input-group">
                    <div class="input-group-prepend"><span class="input-group-text" style="border: 0;box-shadow: inset 0 -1px 0px rgba(0, 0, 0, 0.25);background: rgba(0, 0, 0, 0.05);border-radius: 0;">Ligne 4</span></div>
                    <input @focus="$event.target.select()" @input="hasMods = true" class="form-control pl-2" :id="'RefroidissementAction4' + currentEtab.eId" type="text" v-model="currentEtab.objDS.RefroidissementAction4" 
                          maxlength="60" />
                  </div>
                </fieldset>

                <div class="col-sm-12" style="text-align:right;">
                  <b-button class="btn vertAgap" @click="saveEtab" :disabled="erreurMail || !hasMods" title="Sauvegarder cet établissement" alt="Enregistrer">Enregistrer</b-button>
                </div>
              </div>
            </div>
          </div>

          <div class="w-100"></div>

        </div>
      </div>

      <div class="tab-pane" id="LieuxLivraison">
        <b-modal id="modalTriLieux" size="xl" title="Ordre des lieux de portage (modification par glisser / déposer)"
          no-close-on-backdrop
          ok-title="Enregistrer"
          cancel-title="Annuler"
          @ok="enregistrerTri()">
          <template slot="default">
            <div class="row" :key="keyTri">
              <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" v-for="lieu in lieuxTri" :key="lieu.LieuId" @dragstart="dragStart($event, lieu)" @drop="drop($event, lieu)" @dragover="dragOver($event)">
                <div class="btn btn-primary w-100 mb-2" draggable="true">
                  ({{ lieu.Ordre}}) {{ lieu.libelle }}
                </div>
              </div>
            </div>
          </template>
        </b-modal>

        <b-modal id="modalEditionJours" size="lg" title="Modifier les jours d'ouverture des lieux de portage"
          no-close-on-backdrop
          ok-title="Enregistrer"
          cancel-title="Annuler"
          @ok="enregistrerJours()">
          <template slot="default">
            <table style="table-layout:fixed;">
              <colgroup>
                <col width="*">
                <col width="80px">
                <col width="80px">
                <col width="80px">
                <col width="80px">
                <col width="80px">
                <col width="80px">
                <col width="80px">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <td>Lieu de portage</td>
                  <td>Lundi</td>
                  <td>Mardi</td>
                  <td>Mercredi</td>
                  <td>Jeudi</td>
                  <td>Vendredi</td>
                  <td>Samedi</td>
                  <td>Dimanche</td>
                  <td>Actif</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="lieu in lieuxJours" :key="lieu.id">
                  <td>{{lieu.libelle}}</td>
                  <td><input type="checkbox" :checked="lieu.joursOuverture.includes(1)" @change="jourChanged(lieu, 1, $event.currentTarget.checked)" /></td>
                  <td><input type="checkbox" :checked="lieu.joursOuverture.includes(2)" @change="jourChanged(lieu, 2, $event.currentTarget.checked)" /></td>
                  <td><input type="checkbox" :checked="lieu.joursOuverture.includes(3)" @change="jourChanged(lieu, 3, $event.currentTarget.checked)" /></td>
                  <td><input type="checkbox" :checked="lieu.joursOuverture.includes(4)" @change="jourChanged(lieu, 4, $event.currentTarget.checked)" /></td>
                  <td><input type="checkbox" :checked="lieu.joursOuverture.includes(5)" @change="jourChanged(lieu, 5, $event.currentTarget.checked)" /></td>
                  <td><input type="checkbox" :checked="lieu.joursOuverture.includes(6)" @change="jourChanged(lieu, 6, $event.currentTarget.checked)" /></td>
                  <td><input type="checkbox" :checked="lieu.joursOuverture.includes(0)" /></td>
                  <td><input type="checkbox" :checked="lieu.actif" @change="jourChanged(lieu, -1, $event.currentTarget.checked)" /></td>
                </tr>
              </tbody>
            </table>
          </template>
        </b-modal>

        <div class="row mb-2 mt-2">
          <div class="col-12">
            <button class="btn btn-danger mr-2" @click="ajouterLieu">Ajouter un lieu de portage</button>
            <div v-if="lieux.length" style="display:inline;">
              <input v-model="filtreLieux" type="text" style="height:33.5px;" placeholder="Recherche lieu de portage">
              <span v-if="filtreLieux" v-tooltip="{ content: 'Effacer la recherche'}" @click="filtreLieux = ''" class="fas fa-window-close" style="position:relative;margin-left:-15px;vertical-align:sub;"></span>
            </div>
            <button class="btn ml-2 mr-2 bleuAgap" @click="trierLieux">Trier les lieux de portage</button>
            <button class="btn mr-2 bleuAgap" @click="modifierJours">Modifier les jours d'ouverture</button>
          </div>
        </div>
        <div class="row">
          <div :class="'col-12 row'">
            <div :class="'col-xl-3 col-lg-4 col-md-6 col-sm-12'" v-for="lieu in lieuxFiltres" :key="lieu.id">
              <div class="card">
                <div :class="['card-header','flex-center-space-between',{'bg-secondary':!lieu.actif}]">
                  <div v-if="lieu.actif">
                    <i class="fas fa-check mr-1"></i>
                    Actif
                  </div>
                  <div v-else>
                    <i class="fas fa-times mr-1"></i>
                    Inactif
                  </div>
                  <div>
                    <button class="btn btn-primary btn-sm" v-tooltip="{ content: 'Modifier ce lieu de portage'}" @click="modifierLieu(lieu.id)"><i class="fas fa-edit"></i></button>
                    <button class="btn btn-danger btn-sm ml-2" v-tooltip="{ content: 'Effacer le lieu de portage'}" @click="supprimerLieu(lieu.id)"><i class="fas fa-times" ></i></button>
                  </div>
                </div>
                <div :class="['card-header',{'bg-secondary':!lieu.actif}]" style="border-radius:0;">
                  {{ lieu.libelle }}
                </div>
                <div class="card-body" style="height:100%" :style="!lieu.actif ? 'opacity:65%' : ''">
                  <p>{{lieu.adresse1}}</p>
                  <p>{{lieu.adresse2}}</p>
                  <p v-if="lieu.adresse3">{{lieu.adresse3}}</p>
                  <p>{{lieu.codePostal}} {{lieu.ville}}</p>
                  <p v-if="joursOuverture(lieu)" style="margin-top:10px;">{{joursOuverture(lieu)}}</p>
                  <fieldset class="form-group">
                    <label>Effectifs par catégorie</label>
                    <div v-for="(item, index) in lieu.effectifs" :key="index" class="flex-center-space-between" style="margin-bottom:5px">
                      <p>{{item.structure}} {{item.service}} {{item.regime}} {{item.texture}} :</p><p style="white-space:nowrap;"><i v-if="item.aPlatTemoin" title="Plat témoin" class="icone-platTemoin" style="font-size:1.3em;margin-right:2px;vertical-align:middle;"></i>{{item.effectif}}</p>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, numeric, minLength, maxLength } from "vuelidate/lib/validators"
import { connecteData } from "@/mixins/connecteData"
import vm from "@/helpers/vm-toolkit-vue"
import { setInfosEtablissement, setLieuxPortage, sortLieuxPortage, getLieuxPortage, supprimerLieuPortage, groupeModifLieuxPortage, currentUserInfoComplet } from '@/api/gpao/user'
import { showAlerts } from "@/mixins/alerts"
import EditionLieuPortage from '@/components/Divers/EditionLieuPortage'
export default {
  name: "profil",
  mixins: [connecteData, showAlerts],
  data() {
    return {
      currentFournId: null,
      currentEtab: { eId: 0 ,objDS: {}},
      tmpMail: "",
      erreurMail: false,
      hasMods: false,
      lieux: [],
      structures: [],
      nouveauLieu: {},
      enEditionLieu: {},
      filtreLieux: "",
      lieuxTri: [],
      lieuxJours: [],
      utilisateurComplet: {},
      keyTri: 1
    }
  },
  components: { EditionLieuPortage },
  computed: {
    lieuxFiltres() {
      var lieux
      if (!this.filtreLieux) {
        lieux = this.lieux
      } else {
        lieux = this.lieux.filter(lieu => lieu.libelle.toLocaleLowerCase().indexOf(this.filtreLieux.toLocaleLowerCase()) > -1)
      }
      return lieux.sort((a, b) => a.ordreLivraison - b.ordreLivraison)
    }
  },
  watch: {
    etabCourant(newEtab) {
      this.selectEtab(newEtab.eId)
    }
  },
  validations: {
    currentEtab: {
      eRS: { required, maxLength: maxLength(120) },
      adresse1: { maxLength: maxLength(80) },
      adresse2: { maxLength: maxLength(80) },
      adresse3: { maxLength: maxLength(80) },
      codePostal: { required, numeric, minLength: minLength(5), maxLength: maxLength(5) },
      ville: { maxLength: maxLength(80) },
      tel: { maxLength: maxLength(50) }
    },
    tmpMail: { maxLength: maxLength(50) }
  },
  methods: {
    async selectEtab(etablissementId) {
      let result = await this.askSave()
      if (!result) {
        this.currentEtab = vm.clone(this.utilisateurCourant.etablissements.find(el => el.eId === etablissementId))
        this.currentEtab.objDS={}
        if (this.currentEtab.donneesSupplementaires) {
            this.currentEtab.objDS=JSON.parse( this.currentEtab.donneesSupplementaires)
        }
      
        this.hasMods = false
        this.nouveauLieu.etablissementId = this.currentEtab.eId
        this.tmpMail = this.currentEtab.email || ""
        this.chargerLieux()
        this.chargerInfoComplet()
      }
    },
    checkMail() {
      if (this.tmpMail && !vm.is.email(this.tmpMail)) {
        this.erreurMail = true
      } else {
        this.currentEtab.email = this.tmpMail
        this.erreurMail = false
      }
      return !this.erreurMail
    },
    saveEtab() {
      if (!this.currentEtab.objDS.NUMAGREMENT)
        delete this.currentEtab.objDS.NUMAGREMENT
      if (!this.currentEtab.objDS.RepartitionProdParLieu)
        delete this.currentEtab.objDS.RepartitionProdParLieu
      if (!this.currentEtab.objDS.BLAfficherDouteAllergene)
        delete this.currentEtab.objDS.BLAfficherDouteAllergene
      if (!this.currentEtab.objDS.EtablissementDlcPortage)
        delete this.currentEtab.objDS.EtablissementDlcPortage
      if (!this.currentEtab.objDS.BLAfficherEtiquettes)
        delete this.currentEtab.objDS.BLAfficherEtiquettes
      if (!this.currentEtab.objDS.BLDoubleExemplaire)
        delete this.currentEtab.objDS.BLDoubleExemplaire
      if (!this.currentEtab.objDS.BLControleDetaille)
        delete this.currentEtab.objDS.BLControleDetaille
      if (!this.currentEtab.objDS.BLAfficherEffectifVide)
        delete this.currentEtab.objDS.BLAfficherEffectifVide
      if (!this.currentEtab.objDS.RefroidissementValeur)
        delete this.currentEtab.objDS.RefroidissementValeur
      if (!this.currentEtab.objDS.RefroidissementTolerance)
        delete this.currentEtab.objDS.RefroidissementTolerance
      if (!this.currentEtab.objDS.RefroidissementAction1)
        delete this.currentEtab.objDS.RefroidissementAction1
      if (!this.currentEtab.objDS.RefroidissementAction2)
        delete this.currentEtab.objDS.RefroidissementAction2
      if (!this.currentEtab.objDS.RefroidissementAction3)
        delete this.currentEtab.objDS.RefroidissementAction3
      if (!this.currentEtab.objDS.RefroidissementAction4)
        delete this.currentEtab.objDS.RefroidissementAction4
      if (!this.currentEtab.objDS.MasquerStructMenusAgap)
        delete this.currentEtab.objDS.MasquerStructMenusAgap
      this.currentEtab.donneesSupplementaires=JSON.stringify(this.currentEtab.objDS);
      setInfosEtablissement(this.currentEtab)
        .then(function () {
          this.$store.commit('user/setInfosEtablissement', this.currentEtab)
          this.hasMods = false
          this.toastSucces('Modifications enregistrées')
        }.bind(this))
        .catch(e => {
          console.error(e)
          this.toastErreur("Echec de l'enregistrement")
        })
    },
    async askSave() {
      if (!this.hasMods) {
        return Promise.resolve(false)
      }
      return this.alerteConfirmationEnregistrement()
    },
    ajouterLieu() {
      this.nouveauLieu = {
        id: -1,
        etablissementId: this.currentEtab.eId,
        actif: true,
        libelle: 'Nouveau lieu',
        adresse1: '',
        adresse2: '',
        adresse3: '',
        codePostal: '',
        ville: '',
        effectifs: [],
        ordreLivraison: this.lieux.length ? Math.max.apply(Math, this.lieux.map(l => l.ordreLivraison)) + 1 : 1,
        joursOuverture: []
      }
      this.$refs.editionLieuPortage.show(this.nouveauLieu)
    },
    enregistrerLieu(lieu) {
      this.$refs.editionLieuPortage.hide()
      return setLieuxPortage([lieu])
        .then(() => {
          this.chargerLieux()
          this.alerteSucces('Lieu enregistré !')
          return
        })
    },
    trierLieux() {
      this.lieuxTri = this.lieux.map(lieu => {
        return {
          LieuId: lieu.id,
          libelle: lieu.libelle,
          Ordre: lieu.ordreLivraison
        }
      })
      this.lieuxTri.sort((a, b) => a.Ordre - b.Ordre)
      this.$bvModal.show('modalTriLieux')
    },
    dragStart(e, lieu) {
      e.dataTransfer.dropEffect = "move"
      e.dataTransfer.setData("text/plain", lieu.LieuId)
    },
    dragOver(e) {
      e.preventDefault()
    },
    drop(e, destination) {
      e.preventDefault()
      var source = this.lieuxTri.find(l => l.LieuId === Number(e.dataTransfer.getData("text/plain")))
      var ordreDest = destination.Ordre
      if (source.Ordre < destination.Ordre) {
        this.lieuxTri.forEach(lieu => {
          if (lieu.Ordre > source.Ordre && lieu.Ordre <= destination.Ordre && lieu.LieuId !== source.LieuId) {
            lieu.Ordre -= 1
          }
        })
      } else if (source.Ordre > destination.Ordre) {
        this.lieuxTri.forEach(lieu => {
          if (lieu.Ordre < source.Ordre && lieu.Ordre >= destination.Ordre && lieu.LieuId !== source.LieuId) {
            lieu.Ordre += 1
          }
        })
      }
      source.Ordre = ordreDest
      this.lieuxTri = this.lieuxTri.sort((a, b) => a.Ordre - b.Ordre)
    },
    annulerTri() {
      this.$bvModal.hide('modalTriLieux')
    },
    enregistrerTri() {
      this.annulerTri()
      this.lieuxTri.forEach(lieu => {
        this.lieux.find(l => l.id === lieu.LieuId).ordreLivraison = lieu.Ordre
      })
      return sortLieuxPortage(this.lieuxTri)
        .then(() => {
          this.alerteSucces('Tri enregistré !')
          return
        })
    },
    fermerModalEdition() {
      this.$refs.editionLieuPortage.hide()
    },
    async chargerLieux() {
      var response = await getLieuxPortage(this.currentEtab.eId, true)
      this.lieux = response.data.lieux
      this.structures = response.data.structures
      this.$store.commit('user/setALieuPortage', {eId: this.currentEtab.eId, aLieuPortage: response.data.lieux && response.data.lieux.filter(l => l.actif).length > 0})
    },
    async chargerInfoComplet() {
      let rep = await currentUserInfoComplet()
      this.utilisateurComplet = rep.data
    },
    async supprimerLieu(lieuId) {
      var lieu = this.lieux.find(x => x.id === lieuId)
      const r = await this.alerteConfirmationSuppression('Supprimer le lieu de portage', "Souhaitez-vous supprimer le lieu de portage \"" + lieu.libelle + "\" ?")
      if (r) {
        return supprimerLieuPortage(lieuId)
          .then(() => {
            this.chargerLieux()
            this.alerteSucces('Lieu supprimé !')
            return
          })
      }
    },
    modifierLieu(lieuId) {
      this.$refs.editionLieuPortage.show(vm.clone(this.lieux.find(x => x.id === lieuId)))
    },
    joursOuverture(lieu) {
      var txt = ""
      lieu.joursOuverture.forEach(jour => {
        if (jour === 0) {
          txt += (txt ? ", " : "") + "Di"
        } else if (jour === 1) {
          txt += (txt ? ", " : "") + "Lu"
        } else if (jour === 2) {
          txt += (txt ? ", " : "") + "Ma"
        } else if (jour === 3) {
          txt += (txt ? ", " : "") + "Me"
        } else if (jour === 4) {
          txt += (txt ? ", " : "") + "Je"
        } else if (jour === 5) {
          txt += (txt ? ", " : "") + "Ve"
        } else if (jour === 6) {
          txt += (txt ? ", " : "") + "Sa"
        }
      })
      if (txt) {
        txt = "Ouvert le : " + txt + "."
      }
      return txt
    },
    modifierJours() {
      this.lieuxJours = this.lieux.map(lieu => {
        return {
          lieuId: lieu.id,
          actif: lieu.actif,
          libelle: lieu.libelle,
          joursOuverture: vm.clone(lieu.joursOuverture),
          mod: false
        }
      })
      this.$bvModal.show("modalEditionJours")
    },
    jourChanged(lieu, jour, ouvert) {
      if (jour === -1) {
        lieu.actif = ouvert
        lieu.mod = true
      } else if (ouvert && !lieu.joursOuverture.includes(jour)) {
        lieu.joursOuverture.push(jour)
        lieu.mod = true
      } else if (!ouvert && lieu.joursOuverture.includes(jour)) {
        lieu.joursOuverture.splice(lieu.joursOuverture.indexOf(jour), 1)
        lieu.mod = true
      }
      lieu.joursOuverture = lieu.joursOuverture.sort((a, b) => (a || 7) - (b || 7))
    },
    enregistrerJours() {
      this.fermerJours()
      this.lieuxJours.forEach(lj => {
        var lieu = this.lieux.find(l => l.id === lj.lieuId)
        lieu.joursOuverture = lj.joursOuverture
        lieu.actif = lj.actif
      })
      var mods = this.lieuxJours.filter(j => j.mod)
      if (!mods.length) {
        return
      }
      return groupeModifLieuxPortage(mods)
        .then(() => {
          this.alerteSucces("Jours d'ouverture enregistrés !")
          return
        })
    },
    fermerJours() {
      this.$bvModal.hide("modalEditionJours")
    }
  },
  created() {
    this.selectEtab(this.etabCourantId)
  },
  beforeRouteLeave(to, from, next) {
    console.log('checkRouteProfile')
    return this.askSave()
      .then(result => {
        if (result) {
          // Enregistrement de l'établissement
          this.saveEtab()
        }
        // eslint-disable-next-line promise/no-callback-in-promise
        next()
        return
      })
  }
}
</script>

<style scoped>

.nav.nav-tabs .nav-item a.nav-link.active {
  background-color: #4d80d1 !important;
  border-radius: 10px;
  border-width: 0;
  color: white !important;
}
.nav.nav-tabs.nav-linetriangle .nav-item a.nav-link.active {
  color: white !important;
}
.nav.nav-tabs .nav-item .nav-link {
  /* barre d'onglets' */
  background-color: white !important;
  border-radius: 10px;
  border-width: 0;
  color: #4d80d1;
}

.nav {
  border-width: 0;
  margin-bottom: 40px;
}
.nav.nav-tabs a.nav-link.active:before,
.nav.nav-tabs a.nav-link.active:after {
  border: none !important;
}
p {
  font-size: 12px;
  margin-bottom: 5px;
}
.img-wrapper {
  /* logo */
  position: relative;
}
.img-logo {
  border-radius: 60px;
  box-shadow: 2px 2px 2px 2px lightgrey;
  display: block;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 120px;
}
.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.img-overlay:before {
  content: " ";
  display: block;
  height: 85%;
}
.ovale-agrement {
  fill: white;
  stroke:lightgray;
  stroke-width: 2;
}
#modalEditionJours td {
  text-align: center;
}
</style>
