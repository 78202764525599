import Vue from "vue"
import App from "./App.vue"
import router from "./router/index"
import store from "./store"
import BootstrapVue from "bootstrap-vue"
import Vuelidate from 'vuelidate'
// eslint-disable-next-line
import Toast from 'vue-easy-toast'
import VuePaginate from 'vue-paginate'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VCalendar from 'v-calendar'
import FunctionalCalendar from 'vue-functional-calendar'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import filters from './helpers/filters';
import VueMatomo from "vue-matomo";

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import ToggleButton from 'vue-js-toggle-button'
import { setUserBugsnag } from "@/helpers/bugsnagM5"


import 'material-design-icons-iconfont'

Vue.component('v-select', vSelect)
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)
// eslint-disable-next-line
//Vue.config.devtools = true
Vue.use(filters);
Vue.use(ToggleButton)
Vue.use(VCalendar, {
  
})
Vue.use(VueSweetalert2)
Vue.use(VuePaginate)
Vue.use(Toast)
Vue.use(Vuelidate)
// Vue.use(VueIziToast)
Vue.use(BootstrapVue)
Vue.use(VueMatomo, {
  host: "https://matomo.agap.pro",
  siteId: process.env.VUE_APP_MATOMO_SITE_ID,
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  debug: true
})
Vue.use(FunctionalCalendar, {
  dayNames: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
  shortMonthNames: ['Jan', 'Fev', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
  monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
})
Vue.config.productionTip = false

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

Object.defineProperty(Array.prototype, 'first', {
  value() {
    if (!this.length)
      return null
    return this[0]
  }
})
Object.defineProperty(Array.prototype, 'last', {
  value() {
    if (!this.length)
      return null
    return this[this.length - 1]
  }
})

Bugsnag.start({ apiKey: '0b3a2d5e29d4f8f83c1b6e2143d9c888', plugins: [new BugsnagPluginVue(Vue)], appVersion:document.documentElement.dataset.buildTimestamp })
Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)

/*
Vue.config.errorHandler = function (err, vm, info) {
 // console.dir(err)
  alert("Erreur non traitée " + err)
  Bugsnag.notify(err)
  throw err
}
*/

new Vue({ router, store, render: h => h(App) }).$mount("#app")

if (store.state.user) {
  setUserBugsnag(store.state.user.userInfo)
}
// Nettoyage ancien storage devenu obsolete par évolution du code
// à supprimer a terme
localStorage.removeItem('development-vuex')
localStorage.removeItem('production-vuex')
localStorage.removeItem('vuex')