<template>
  <div class="container-fluid">

    <b-modal id="modalDetailsSpeciaux" :size="'xxl'" :title="'Documents spéciaux pour ' + currentSpeciaux.nomFournisseur" ok-only
      modal-class="height-responsive" body-class="height-responsive-body height-responsive-0b"
      ok-title="Fermer"
      no-close-on-backdrop>
      <template slot="default">
        <div class="container-fluid">
          <div class="row">
            <div class="col-6" style="text-align: right">
              <div v-if="currentSpeciaux.urlLogo !== ''"><img class="speciaux-logo" :src="currentSpeciaux.urlLogo"></div>
            </div>
            <div class="col-6" style="text-align: center">
              <div style="text-align: left">
                <br/>
                <div class="bold600">{{ currentSpeciaux.nomFournisseur }}</div>
                <div class="text-sm">{{ currentSpeciaux.contact }}</div>
                <div class="text-sm">{{ currentSpeciaux.adresse }}</div>
              </div>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-for="spe in currentSpeciaux.listeParCategorie" :key="spe.idFournisseur">
              <div class="card" style="cursor:pointer;heigth:160px;">
                <div :class="'card-header background-primary'">
                  {{ spe.nomCategorie }}
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12" v-for="doc in spe.listeSpeciaux" :key="doc.speciauxId" @click="incrementCompteurSpeciaux(doc.speciauxId)">
                      <a :href="(doc.video ? doc.lienVideo : doc.cheminWeb)" target="_blank" rel="noopener noreferrer">
                        <div class="card">
                          <div class="card-body">
                            <span :class="'bold600 ' + (doc.estNouveau ? 'text-success' : 'text-black')">
                              {{doc.estNouveau ? '(nouveau)&nbsp;&nbsp;' : ''}}{{doc.nom}}&nbsp;&nbsp;
                              <i class="far fa-lg" :class="(doc.video ? 'fa-file-video' : 'fa-file-pdf')"></i>
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <BarreFiltres ref="barreFiltres" :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />

    <div class="card height-responsive">
      <div class="card-header">Cliquez sur un fournisseur pour accéder aux documents disponibles</div>
      <div class="card-body height-responsive-body height-responsive-1b">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" v-for="spe in speciaux" :key="spe.idFournisseur">
              <div class="card" @click="drawSpeciaux(spe)" style="cursor:pointer;heigth:160px;">
                <div class="card-header" :class="[{'bg-success':nombreFichiersNouveaux(spe)}]">
                  {{ spe.nomFournisseur }}
                </div>
                <div class="card-body container-fluid mh-120">
                  <div class="row" style="height: 100px;">
                    <div class="col-8">
                      <span class="bold600">{{nombreFichiers(spe)}}</span><span class="bold600 text-success">{{nombreFichiersNouveaux(spe)}}</span>
                      <p class="text-sm">{{spe.listeCategorie}}</p>
                      <p class="text-sm">{{spe.listeFamille}}</p>
                    </div>
                    <div class="col-4 d-flex flex-wrap align-items-center">
                      <div v-if="spe.urlLogo !== ''"><img class="speciaux-liste-logo" :src="spe.urlLogo"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from "@/mixins/alerts"
import { chargerSpeciaux, incrementerCompteurSpeciaux } from '@/api/gpao/produits'
import { rechercherMultiTexte } from '@/helpers/utils'
import _ from 'lodash'

export default {
  name: "PageSpeciaux",
  components: {
    BarreFiltres
  },
  mixins: [connecteData, showAlerts],
  data() {
    return {
      speciaux: [],
      filtredocuments: '',
      currentSpeciaux : [],
      listeSpeciauxEnMemoire : [],
      listeFamilles : [],
      listeFamillesAvecInfos : [],
      filtreFamilles : [],
      filtreTypeFamilles : ["Tous"],
      isLoading: false,
      afficheNonAlimentaire: false,
      filtres: {
        nomcateg : {
          label: "Recherche un document",
          champ: "input",
          type: "text",
          selection: "",
          initialiser(contexte) {
            contexte.filtres.nomcateg.selection = contexte.filtredocuments
          },
          filtrer(contexte) {
            contexte.filtredocuments = contexte.filtres.nomcateg.selection
            contexte.filtrer()
          }
        },
        familles: {
          label: "Famille du fournisseur",
          champ: "select",
          type: "multi",
          width: "500px",
          maxDisplay: 3,
          options: [],
          selection: [],
          initialiser(contexte) {
            let filtre = contexte.filtres.familles
            filtre.options = contexte.listeFamilles//.map(zs => new { valeur: zs, texte: zs })
            filtre.selection = contexte.filtreFamilles
          },
          filtrer(contexte) {
            contexte.filtreFamilles = contexte.filtres.familles.selection
            contexte.filtrer()
          }
        },
        typefournisseur: {
          label: "Type de familles",
          champ: "select",
          width: "300px",
          maxDisplay: 3,
          options: ["Alimentaire", "Non Alimentaire", "Tous"],
          selection: [],
          initialiser(contexte) {
            let filtre = contexte.filtres.typefournisseur
            filtre.options = ["Alimentaire", "Non Alimentaire", "Tous"] //contexte.listeFamilles//.map(zs => new { valeur: zs, texte: zs })
            filtre.selection = contexte.filtreTypeFamilles
          },
          filtrer(contexte) {
            contexte.filtreTypeFamilles = contexte.filtres.typefournisseur.selection
            if (contexte.filtreTypeFamilles != "Tous") 
              contexte.filtres.familles.options = contexte.listeFamillesAvecInfos.filter(n => n.item3 === (contexte.filtreTypeFamilles === "Non Alimentaire")).map(f => f.item2)
            else
              contexte.filtres.familles.options = contexte.listeFamillesAvecInfos.map(f => f.item2)
            
            contexte.filtrer()
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.reinitialiser()
          contexte.filtres.barreGauche = [ contexte.filtres.typefournisseur, contexte.filtres.familles, contexte.filtres.nomcateg ]
          contexte.filtres.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
        },
        filtrer(contexte, commande) {
          return !contexte.filtres.barreFiltres.find(f => !f.filtrer(contexte, commande))
        }
      }
    }
  },
  methods: {
    //Pour ouvrir la modale avec la liste des documents spéciaux du fournisseur sélectionné
    drawSpeciaux(spe) {
      this.currentSpeciaux = spe
      this.$bvModal.show('modalDetailsSpeciaux')
    },
    nombreFichiers(spe) {
      return spe.nbFichiers + " fichiers";
    },
    nombreFichiersNouveaux(spe) {
      return (spe.nbFichiersNouveau > 0 ? " dont " + spe.nbFichiersNouveau + " nouveau" : "");
    },

    reinitialiser() {
      this.speciaux = _.cloneDeep(this.listeSpeciauxEnMemoire)
    },
    filtrer() {
      setTimeout(() => { // affiche l'attente avant de continuer
        this.speciaux = _.cloneDeep(this.listeSpeciauxEnMemoire)//application des filtres
        this.filtrerSpeciaux()
      }, 1)
    },
    filtrerSpeciaux() {
      var speciauxFiltre

      if (this.filtreTypeFamilles && this.filtreTypeFamilles != "Tous") {
        speciauxFiltre = []
        this.speciaux.forEach(z => {
        if (((this.filtreTypeFamilles == "Non Alimentaire") && z.contientNonAlimentaire && speciauxFiltre.filter(e => e.nomFournisseur.includes(z.nomFournisseur)).length == 0) || 
            ((this.filtreTypeFamilles == "Alimentaire") && z.contientAlimentaire && speciauxFiltre.filter(e => e.nomFournisseur.includes(z.nomFournisseur)).length == 0))
          speciauxFiltre.push(_.cloneDeep(z))
        })
        this.speciaux = speciauxFiltre.filter((val, ind, arr) => arr.indexOf(val) === ind) //La liste des spéciaux à afficher sur la page est filtrée par rapport à la saisie
      }
      //Pour chaque VueSpeciaux, on retourne la vue qui contient la recherche dans le champ rechercheSpeciaux (Nom des documents, liste des catégories) ou si le nom du fournisseur correspond à la recherche
      if (this.filtredocuments) {
        speciauxFiltre = []
        this.speciaux.forEach(z => {
          let rechSpecTot = z.rechercheSpeciaux.join(" ");
          if ((rechercherMultiTexte(rechSpecTot, this.filtredocuments) || rechercherMultiTexte(z.nomFournisseur, this.filtredocuments)) && !speciauxFiltre.find(e => e.nomFournisseur.includes(z.nomFournisseur)))
             speciauxFiltre.push(_.cloneDeep(z))
        })
        this.speciaux = speciauxFiltre.filter((val, ind, arr) => arr.indexOf(val) === ind) //La liste des spéciaux à afficher sur la page est filtrée par rapport à la saisie
      }

      //Pour chaque VueSpeciaux déjà filtré, on retourne la vue qui contient la ou les familles sélectionnées
      if (this.filtreFamilles && this.filtreFamilles.length > 0) {
        speciauxFiltre = []
        this.filtreFamilles.forEach(f => {
            this.speciaux.forEach(z => {
            var s = z.tableauFamilles.filter(n => n.includes(f))
            if (s.length > 0 && speciauxFiltre.filter(e => e.nomFournisseur.includes(z.nomFournisseur)).length == 0) //On n'ajoute pas la vue si déjà présente
              speciauxFiltre.push(_.cloneDeep(z))
          })
        })
        this.speciaux = speciauxFiltre.filter((val, ind, arr) => arr.indexOf(val) === ind) //La liste des spéciaux à afficher sur la page est filtrée par rapport à la saisie
      } 
    },
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
    async chargerSpeciaux() {
      this.isLoading = true
      this.$store.commit('uxHelpers/showSpinner')
      this.speciaux = []
      this.listeFamilles = []
      this.listeFamillesAvecInfos = []
      let tabFam = []
      let response = await chargerSpeciaux(this.etabCourantId)
      if (!response) {
        this.$store.commit('uxHelpers/hideSpinner')
        this.isLoading = false
        return
      }
      this.speciaux = response.data

      this.speciaux.forEach(x => {
        tabFam.push(...x.tableauTriFamilles)
      })

      //On tri la liste des familles par indice (item1 du tableau)
      var sortFam = tabFam.sort(function(a, b) {
          return a.item1 - b.item1
      })

      //On supprime les doublons de familles, et on ne prend que le nom de la famille en retour (item2 du tableau)
      this.listeFamilles = [sortFam[0].item2]
      this.listeFamillesAvecInfos = [sortFam[0]]
      for (var i = 1; i < sortFam.length; i++) { 
        if (sortFam[i-1].item2 !== sortFam[i].item2) {
          this.listeFamilles.push(sortFam[i].item2);
          this.listeFamillesAvecInfos.push(sortFam[i])
        }
      }

      this.listeSpeciauxEnMemoire = _.cloneDeep(response.data)
      this.$store.commit('uxHelpers/hideSpinner')
      this.isLoading = false
    },

    incrementCompteurSpeciaux(speciauxId) {
      incrementerCompteurSpeciaux(speciauxId)
    }
  },
  mounted() {
    
  },
  async created() {
    await this.chargerSpeciaux() //On attend le chargement de la page avant d'initialiser les filtres, necessaire pour la liste des familles
    this.filtres.initialiser(this)
  }

}
</script>


