import { connecteData } from '@/mixins/connecteData'
import { arrondiNombre, qtePCB, prixPCB } from '@/helpers/utils'
import { mapGetters } from "vuex"
import { xlsx } from "@/mixins/xlsx"
import { formatHumanDateAvecSlashes, formatHumanDate } from "@/mixins/dateFormats"

export const commande = {
  mixins: [connecteData, xlsx, formatHumanDateAvecSlashes],
  data() {
    return {
      impressionCmd1TableauEnCours: false
    }
  },
  computed: {
    ...mapGetters("fournisseurs", ["nomCourtFournisseur"]),
    ...mapGetters("definitions", ["obtenirFamille"]),
  },
  methods: {
    generer(commande, lignesTriees) {
      this.impressionCmd1TableauEnCours = true

      var allBorders = {//styles
				left: {style: "thin", color: {auto: 1}},
				right: {style: "thin", color: {auto: 1}},
				top: {style: "thin", color: {auto: 1}},
				bottom: {style: "thin", color: {auto: 1}}
      }
      var styles = {
        bold: {
          font: {bold: true}
        },
        boldRight: {
          alignment: {horizontal: "right"},
          font: {bold: true}
        },
        right: {
          alignment: {horizontal: "right"}
        },
        head: {
          border: allBorders,
					fill: {fgColor: {rgb: "FF808080"}},
					font: {sz: 11, bold: true, color: {rgb: "FFFFFFFF"}}
        },
        cell: {
          border: allBorders,
          font: {sz: 11}
        },
        firstCell: {
          border: {
            left: {style: "thin", color: {auto: 1}},
            right: {style: "thin", color: {auto: 1}},
            top: {style: "medium", color: {auto: 1}},
            bottom: {style: "thin", color: {auto: 1}}
          },
          font: {sz: 11}
        }
      }
      var worksheet = {
        "!pageSetup": {
          scale: 80,
          orientation: 'landscape'
        },
        "!cols": [
          {wch: 11},
          {wch: 5},
          {wch: 9},
          {wch: 25},
          {wch: 20},
          {wch: 20},
          {wch: 8},
          {wch: 8},
          {wch: 10},
          {wch: 10},
          {wch: 10}
        ],
        "!merges": [{
          s: {c: 0, r: 5},
          e: {c: 10, r: 6}
        }, {
          s: {c: 3, r: 8},
          e: {c: 4, r: 8}
        }],
        "A1": {
					v: this.etabCourant.eRS || "",
					t: "s",
					s: styles.bold
				},
        "A2": {
					v: this.etabCourant.adresse1 || "",
					t: "s"
				},
        "A3": {
					v: this.etabCourant.adresse2 || "",
					t: "s"
				},
        "A4": {
					v: this.etabCourant.adresse3 || "",
					t: "s"
				},
        "A5": {
					v: this.etabCourant.codePostal + " " + this.etabCourant.ville,
					t: "s"
				},
        "A6": {
					v: this.etabCourant.commentairePourToutFournisseur || "",
					t: "s",
          s: {
            alignment: {vertical: "center", wrapText: true},
            border: allBorders
          }
				},
        "A7": {v: "", t: "s", s: {border: allBorders}},
        "B6": {v: "", t: "s", s: {border: allBorders}},
        "B7": {v: "", t: "s", s: {border: allBorders}},
        "C6": {v: "", t: "s", s: {border: allBorders}},
        "C7": {v: "", t: "s", s: {border: allBorders}},
        "D6": {v: "", t: "s", s: {border: allBorders}},
        "D7": {v: "", t: "s", s: {border: allBorders}},
        "E6": {v: "", t: "s", s: {border: allBorders}},
        "E7": {v: "", t: "s", s: {border: allBorders}},
        "F6": {v: "", t: "s", s: {border: allBorders}},
        "F7": {v: "", t: "s", s: {border: allBorders}},
        "G6": {v: "", t: "s", s: {border: allBorders}},
        "G7": {v: "", t: "s", s: {border: allBorders}},
        "H6": {v: "", t: "s", s: {border: allBorders}},
        "H7": {v: "", t: "s", s: {border: allBorders}},
        "I6": {v: "", t: "s", s: {border: allBorders}},
        "I7": {v: "", t: "s", s: {border: allBorders}},
        "J6": {v: "", t: "s", s: {border: allBorders}},
        "J7": {v: "", t: "s", s: {border: allBorders}},
        "K6": {v: "", t: "s", s: {border: allBorders}},
        "K7": {v: "", t: "s", s: {border: allBorders}},
        "D2": {
					v: this.etabCourant.tel || "",
					t: "s"
				},
        "D3": {
					v: this.etabCourant.contact || "",
					t: "s"
				},
        "K1": {
          v: commande.nom,
					t: "s",
          s: styles.boldRight
        },
        "K4": {
          v: commande.nbLignes + " lignes",
					t: "s",
          s: styles.right
        },
        "A9": {
          v: "FOURN.",
					t: "s",
          s: styles.head
        },
        "B9": {
          v: "FAM.",
					t: "s",
          s: styles.head
        },
        "C9": {
          v: "REF.",
					t: "s",
          s: styles.head
        },
        "D9": {
          v: "DESIGNATION",
					t: "s",
          s: styles.head
        },
        "E9": {v: "", t: "s", s: styles.head},
        "F9": {
          v: "MARQUE",
					t: "s",
          s: styles.head
        },
        "G9": {
          v: "UC",
					t: "s",
          s: styles.head
        },
        "H9": {
          v: "QTE",
					t: "s",
          s: styles.head
        },
        "I9": {
          v: "PU HT",
					t: "s",
          s: styles.head
        },
        "J9": {
          v: "TOTAL HT",
					t: "s",
          s: styles.head
        },
        "K9": {
          v: "LIVRAISON",
					t: "s",
          s: styles.head
        }
      }

      var iLigne = 10
      lignesTriees.slice().filter(ligne => !ligne.deleted && ligne.qDem && ligne.pId).forEach((ligne, iLF) => {
        let nomFourn = this.nomCourtFournisseur(ligne.foId)
        let fourn = commande.detailFournisseurs.find(f => f.fournisseurId === ligne.foId) || {}
        worksheet["A" + iLigne] = {
          v: nomFourn || "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["B" + iLigne] = {
          v: this.obtenirFamille(ligne.faId).code,
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["C" + iLigne] = {
          v: ligne.Ref || "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["D" + iLigne] = {
          v: ligne.dFou || "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["E" + iLigne] = {
          v: "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["F" + iLigne] = {
          v: ligne.marque || "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["G" + iLigne] = {
          v: ligne.uc || "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["H" + iLigne] = {
          v: qtePCB(ligne.qDem, ligne.pcb),
          t: "n",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["I" + iLigne] = {
          v: arrondiNombre(ligne.pu),
          t: "n",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["J" + iLigne] = {
          v: arrondiNombre(prixPCB(ligne.qDem, ligne.pcb, ligne.pu)),
          t: "n",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["K" + iLigne] = {
          v: this.formatHumanDateAvecSlashes(fourn.dateLivraison || fourn.dateLivraisonSouhaitee),
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["!merges"].push({
          s: {c: 3, r: iLigne - 1},
          e: {c: 4, r: iLigne - 1}
        })
        iLigne += 1
      })

      this.xlsx.saveFile(worksheet, commande.nom.replaceAll("/", "-").replaceAll("\\", "-").replaceAll("?", "-").replaceAll("&", "and"))
      this.impressionCmd1TableauEnCours = false
    },

    exporterReceptionCommande(lignes, nomCommande) {
      let allBorders = {//styles
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } }
      }

      let styles = {
        head: {
          border: allBorders,
          fill: { fgColor: { rgb: "FF808080" } },
          font: { bold: true, color: { rgb: "FFFFFFFF" } },
          alignment: { horizontal: "center" }
        },
        cell: {
          border: allBorders
        }
      }

      let worksheet = {//en-tête
        "!cols": [
          {wch: 50},
          {wch: 35},
          {wch: 11},
          {wch: 11},
          {wch: 11},
          {wch: 11},
          {wch: 11},
          {wch: 11},
          {wch: 5},
          {wch: 10},
          {wch: 8},
          {wch: 12}
        ],
        "!merges": [{
          s: {c: 2, r: 0},
          e: {c: 3, r: 0}
        }, {
          s: {c: 4, r: 0},
          e: {c: 5, r: 0}
        }, {
          s: {c: 6, r: 0},
          e: {c: 7, r: 0}
        }, {
          s: {c: 8, r: 0},
          e: {c: 11, r: 0}
        }],
        "A1": {
          v: "Désignation (réf.)",
          t: "s",
          s: styles.head
        },
        "B1": {
          v: "Raison Incident",
          t: "s",
          s: styles.head
        },
        "C1": {
          v: "Quantité",
          t: "s",
          s: styles.head
        },
        "D1": {
          v: "",
          t: "s",
          s: styles.head
        },
        "E1": {
          v: "Prix",
          t: "s",
          s: styles.head
        },
        "F1": {
          v: "",
          t: "s",
          s: styles.head
        },
        "G1": {
          v: "Total",
          t: "s",
          s: styles.head
        },
        "H1": {
          v: "",
          t: "s",
          s: styles.head
        },
        "I1": {
          v: "Lots",
          t: "s",
          s: styles.head
        },
        "J1": {
          v: "",
          t: "s",
          s: styles.head
        },
        "K1": {
          v: "",
          t: "s",
          s: styles.head
        },
        "L1": {
          v: "",
          t: "s",
          s: styles.head
        },
        "A2": {
          v: "",
          t: "s",
          s: styles.head
        },
        "B2": {
          v: "",
          t: "s",
          s: styles.head
        },
        "C2": {
          v: "Commandée",
          t: "s",
          s: styles.head
        },
        "D2": {
          v: "Livrée",
          t: "s",
          s: styles.head
        },
        "E2": {
          v: "Commandé",
          t: "s",
          s: styles.head
        },
        "F2": {
          v: "Livré",
          t: "s",
          s: styles.head
        },
        "G2": {
          v: "Commandé",
          t: "s",
          s: styles.head
        },
        "H2": {
          v: "Livré",
          t: "s",
          s: styles.head
        },
        "I2": {
          v: "Qté",
          t: "s",
          s: styles.head
        },
        "J2": {
          v: "N°",
          t: "s",
          s: styles.head
        },
        "K2": {
          v: "DLC",
          t: "s",
          s: styles.head
        },
        "L2": {
          v: "DLC/DDM",
          t: "s",
          s: styles.head
        }
      }

      let jLigne = 0
      lignes.forEach((ligne, iLigne) => { //donnees 
        worksheet["A" + (iLigne + 3 + jLigne)] = {
          v: ligne.designationLivree + " (" + ligne.Ref + ")",
          t: "s",
          s: styles.cell
        }
        worksheet["B" + (iLigne + 3 + jLigne)] = {
          v: ligne.raisonIncident ?? "",
          t: "s",
          s: styles.cell 
        }
        worksheet["C" + (iLigne + 3 + jLigne)] = {
          v: ligne.qPCB,
          t: "s",
          s: styles.cell
        }
        worksheet["D" + (iLigne + 3 + jLigne)] = {
          v: ligne.qteLivree,
          t: "s",
          s: styles.cell
        }
        worksheet["E" + (iLigne + 3 + jLigne)] = {
          v: arrondiNombre(ligne.pu, 3),
          t: "s",
          s: styles.cell
        }   
        worksheet["F" + (iLigne + 3 + jLigne)] = {
          v: arrondiNombre(ligne.puLivre, 3),
          t: "s",
          s: styles.cell
        }  
        let totCom =  arrondiNombre(prixPCB(arrondiNombre(ligne.qDem,3), arrondiNombre(ligne.pcb,3), arrondiNombre(ligne.pu,3)))
        worksheet["G" + (iLigne + 3 + jLigne)] = {
          v: arrondiNombre(totCom, 2),
          t: "s",
          s: styles.cell
        } 
        let totLiv = ligne.qteLivree != 0 ?  arrondiNombre(ligne.qteLivree || ligne.quantite,3) * arrondiNombre(ligne.puLivre,3) : 0
        worksheet["H" + (iLigne + 3 + jLigne)] = {
          v: totLiv != 0 ? arrondiNombre(totLiv, 2) : 0,
          t: "s",
          s: styles.cell
        }
        worksheet["I" + (iLigne + 3 + jLigne)] = {
          v: "",
          t: "s",
          s: styles.cell
        }  
        worksheet["J" + (iLigne + 3 + jLigne)] = {
          v: "",
          t: "s",
          s: styles.cell
        }  
        worksheet["K" + (iLigne + 3 + jLigne)] = {
          v: "",
          t: "s",
          s: styles.cell
        }  
        worksheet["L" + (iLigne + 3 + jLigne)] = {
          v: "",
          t: "s",
          s: styles.cell
        }

        let ligneLotIni = iLigne + 3 + jLigne
        let lots = ligne.lots.length > 1 ? ligne.lots : ligne.lots.filter(lot => lot.numero || lot.dlcDluo)
        lots.forEach((lot, j) => {
          if (j !== 0) {
            worksheet["A" + (ligneLotIni + j)] = {
              v: "",
              t: "s",
              s: styles.cell
            } 
            worksheet["B" + (ligneLotIni + j)] = {
              v: "",
              t: "s",
              s: styles.cell
            } 
            worksheet["C" + (ligneLotIni + j)] = {
              v: "",
              t: "s",
              s: styles.cell
            } 
            worksheet["D" + (ligneLotIni + j)] = {
              v: "",
              t: "s",
              s: styles.cell
            }
            worksheet["E" + (ligneLotIni + j)] = {
              v: "",
              t: "s",
              s: styles.cell
            } 
            worksheet["F" + (ligneLotIni + j)] = {
              v: "",
              t: "s",
              s: styles.cell
            } 
            worksheet["G" + (ligneLotIni + j)] = {
              v: "",
              t: "s",
              s: styles.cell
            } 
            worksheet["H" + (ligneLotIni + j)] = {
              v: "",
              t: "s",
              s: styles.cell
            }
          }
          worksheet["I" + (ligneLotIni + j)] = {
            v: lot.quantite,
            t: "s",
            s: styles.cell
          } 
          worksheet["J" + (ligneLotIni + j)] = {
            v: lot.numero ? lot.numero : "",
            t: "s",
            s: styles.cell
          }  
          worksheet["K" + (ligneLotIni + j)] = {
            v: lot.dlc ? "DLC" : "DDM",
            t: "s",
            s: styles.cell
          }
          worksheet["L" + (ligneLotIni + j)] = {
            v: this.formatHumanDate(lot.dlcDluo),
            t: "s",
            s: styles.cell
          } 

          jLigne = (j === 0) ? jLigne : jLigne + 1
        })
      })
  
      this.xlsx.saveFile(worksheet, nomCommande.replaceAll("/", "-").replaceAll("\\", "-").replaceAll("?", "-").replaceAll("&", "and")) //téléchargement du fichier
    }
  }
}
