<template>
  <div class="container-fluid">
    <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />

    <div class="card height-responsive">
      <div class="card-header flex-center-space-between">
        <div class="flex-start">
          <span>{{nomRecette}}</span>
        </div>
      </div>

      <div class="card-body height-responsive-body height-responsive-1b">
        <RecetteEdition ref="recetteEdition" :recetteId="recetteId" :enAjout="enAjout" :enDuplication="enDuplication" :lienRecetteActif="true"
          @recette-initialisee="recetteInitialisee($event)"
          @recette-modifiee="recetteModifiee($event)"
          @recette-changee="recetteChangee($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import BarreActions from '@/components/Divers/BarreActions'
import RecetteEdition from "@/components/Recettes/RecetteEdition"
import { alerteDonneesModifiees } from "@/mixins/index"
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from "@/mixins/alerts"

export default {
  name: "PageRecetteEdition",
  components: { BarreActions, RecetteEdition },
  mixins: [ alerteDonneesModifiees, connecteData, showAlerts ],
  data() {
    return {
      recetteId: null,
      nomRecette: null,
      aValider: false,
      enAjout: false,
      enDuplication: false,
      donneesModifiees: false,
      afficherReinitialisation: false,
      recetteInvalide: false,
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          hidden: Affiche/masque le bouton (bool),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        exporter: {
          label: "Exporter",
          async methode(contexte) {
            await contexte.composantRecetteEdition.exporter()
          }
        },
        exporterJson: {
          label: "Exporter (JSON)",
          methode(contexte) {
            contexte.composantRecetteEdition.exporterJSON()
          }
        },
        reinitialiser: {
          label: "Réinitialiser",
          hidden: false,
          disabled: true,
          class: "btn-danger",
          async methode(contexte) {
            await contexte.composantRecetteEdition.reinitialiser()
          }
        },
        retour: {
          label: "Retour",
          async methode(contexte) {
            await contexte.$router.push({ name: "Recettes", params: { etablissementId: contexte.etabCourantId } })
          }
        },
        annuler: {
          label: "Annuler",
          disabled: true,
          class: "btn-danger",
          async methode(contexte) {
            let result = await contexte.alerteConfirmation("Annuler les modifications", "Vos modifications seront définitivement perdues.<br/>Souhaitez-vous annuler vos modifications ?")
            if (result) {
              await contexte.composantRecetteEdition.loadData(contexte.recetteId)
            }
          }
        },
        enregistrer: {
          label: "Enregistrer",
          disabled: true,
          class: "btn-success",
          methode(contexte) {
            contexte.composantRecetteEdition.enregistrer()
          }
        },
        // Appeler "this.actions.initialiser(this)" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.reinitialiser ]
          contexte.actions.barreDroite = [ contexte.actions.retour, contexte.actions.annuler, contexte.actions.enregistrer ]
          if (!contexte.enAjout) {
            contexte.actions.barreGauche.unshift(contexte.actions.exporter)
            if (contexte.profilIsAdmin) {
              contexte.actions.barreGauche.unshift(contexte.actions.exporterJson)
            }
          }
        },
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        disableEnregistrement(actions, disabled) {
          let actionsATraiter = [ actions.enregistrer ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableAnnuler(actions, disabled) {
          let actionsATraiter = [ actions.annuler ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        }
      }
    }
  },
  computed: {
    composantRecetteEdition() {
      return this.$refs.recetteEdition
    }
  },
  methods: {
    executerAction(methode) {
      methode(this)
    },
    async enregistrerDonneesModifiees() {
      await this.composantRecetteEdition.enregistrerDonneesModifiees()
    },
    recetteInitialisee(donnees) {
      this.nomRecette = donnees.recette.nom
    },
    recetteModifiee(donnees) {
      this.donneesModifiees = !donnees.recetteInvalide && donnees.donneesModifiees // L'alerte sur la saisie invalide est prioritaire sur l'alerte sur les données modifiées lorsque l'utilisateur quitte la page
      this.recetteInvalide = donnees.recetteInvalide
      this.aValider = donnees.aValider
      this.actions.disableEnregistrement(this.actions, !donnees.enregistrementPossible)
      this.actions.disableAnnuler(this.actions, !donnees.donneesModifiees)
      this.actions.reinitialiser.hidden = !donnees.reinitialisationVisible
      this.actions.reinitialiser.disabled = !donnees.reinitialisationPossible
    },
    recetteChangee(recetteId) {
      this.$router.push({
        name: "RecetteEdition",
        params: { recetteId: recetteId }
      })
    }
  },
  created() {
    this.recetteId = this.$route.params.recetteId
    this.enDuplication = (this.$route.name == "RecetteDupliquer")
    this.enAjout = (!this.recetteId && !this.enDuplication)
  },
  mounted() {
    this.actions.initialiser(this)
  },
  async beforeRouteLeave(to, from, next) {
    console.log('RecetteEdition:checkRoute')
    if (this.recetteInvalide) {
      let result = await this.alerteConfirmation("Saisie invalide", "Les données saisies sont invalides, la recette ne peut pas être enregistrée en l'état.<br/>Souhaitez-vous annuler vos modifications ?")
      if (!result) {
        return
      }
    } else if (this.aValider) {
      let result = await this.alerteConfirmation("Valider la recette", "Cette recette n'a jamais été modifiée.<br/>Vous convient-elle ?")
      if (result) {
        try {
          await this.enregistrerDonneesModifiees()
        } catch(erreur) {
          console.error("erreur beforeRouteLeave Validation déclinaison de recette")
          return
        }
      }
    }
    next()
  }
}
</script>
