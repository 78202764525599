import service from '@/helpers/serviceGPAO'

export function ajouterProduit(data) {
  return service.post('produits/produitsHorsMarche/ajouter/', data)
}

export function modifierProduit(produitId, data) {
  return service.patch('produits/modifier/' + produitId, data)
}

export function supprimerProduit(produitId) {
  return service.delete('produits/produitsGreAGre/supprimer/' + produitId)
}

export function obtenirProduit(produitId) {
  return service.get('produits/' + produitId)
}

export function obtenirProduitsFournisseur(fournisseurId) {
  return service.get('produits/produitsFournisseur/' + fournisseurId)
}

export function reordonnerFamille(familleId) {
  return service.get('produits/reordonnerfamille/' + familleId)
}

export function obtenirProduitMinimum(produitId) {
  return service.get('produits/produitMinimum/' + produitId)
}

export function chargerSpeciaux(etablissementId) {
  return service.get('mercuriale/speciaux/obtenirListeSpeciaux/' + etablissementId)
}

export function incrementerCompteurSpeciaux(speciauxId) {
  return service.patch('mercuriale/speciaux/incrementerCompteurSpeciaux/' + speciauxId)
}

export function changerAffectationProduit(produitId) {
  return service.get('produits/changerAffectation/' + produitId)
}
